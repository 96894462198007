import React, { useEffect } from 'react';
import { useService } from 'rc-service';
import { I18nService } from '@agroop/common/services';
import { SidebarManager } from './Sidebar';
import { UiHelper } from './components/UiHelper';
import { SidebarService } from './services/SidebarService';

/**
 * This component is responsible for rendering the ui components out of AppShell like:
 * - Sidebar
 * - Dialog
 * - Toast * todo
 */
export function AppShellProvider({ children }: React.PropsWithChildren<{}>) {
  const i18nService = useService(I18nService);
  const sidebarService = useService(SidebarService);
  useEffect(sidebarService.setReady, []);

  // default translations for dialog buttons
  const dialogButtonsI18n: Record<string, string> = i18nService.tr('buttons');
  // Are translations already loaded and ready to show and is the SidebarManager already mounted?
  const ready = i18nService.state.language && sidebarService.state.ready;

  return (
    <>
      {ready && children}
      <MemoSidebarManager closeCurrent={sidebarService.closeAll} state={sidebarService.state} />
      <MemoUiHelper buttons={dialogButtonsI18n} />
    </>
  );
}

// Memoize this components so they wont re-render on every path change
const MemoSidebarManager = React.memo(SidebarManager);
const MemoUiHelper = React.memo(UiHelper);
