import { Service, ServiceInit } from 'rc-service';

import { getCropListByLocale } from '@agroop/api/commons/crop';
import { getSoilTypeListByLocale } from '@agroop/api/commons/soilType';
import { getConductionSystemByLocale } from '@agroop/api/commons/conSys';
import { getProductionModeListByLocale } from '@agroop/api/commons/prodMode';
import { getJobTitleListByLocale } from '@agroop/api/commons/jobTitle';
import { getPathogenTypeListByLocale } from '@agroop/api/commons/pathogenType';
import { getTypologyListByLocale } from '@agroop/api/commons/typology';
import { getCountryList } from '@agroop/api/accounts/commons';
import { getAllPests } from '@agroop/api/commons/pest';
import { getAllDiseases } from '@agroop/api/commons/disease';
import { getAllWeeds } from '@agroop/api/commons/weed';
import { IdNameResponse } from '@agroop/api/commons/types';
import { CountryResponse } from '@agroop/api/accounts/types';
import { ResponsePromise } from '@agroop/api';
import { getGrowthStagesByLocale } from '@agroop/api/commons/growthStage';
import { getIrrigationSystemDataResponseList } from '@agroop/api/commons/irrigationSystem';

import { ThenArg, Unpacked } from '../utils/types';
import { I18nService, I18nState } from './I18n';
import { getObject } from '../utils/localStorage';
import { listToMap } from '../utils/normalize';

const FETCH_TIMEOUT = 3.6e6; // one hour

export type FixedValues =
  | 'countries'
  | 'crops'
  | 'soilTypes'
  | 'conductionSystems'
  | 'productionModes'
  | 'jobTitles'
  | 'pathogenTypes'
  | 'typologies'
  | 'pests'
  | 'diseases'
  | 'weeds'
  | 'growthStages'
  | 'irrigationSystem';

const getCountries = () =>
  getCountryList().then(v =>
    v
      .map(value => ({
        ...value,
        id: value.code,
      }))
      .sort((a, b) => a.name.localeCompare(b.name)),
  ) as ResponsePromise<(IdNameResponse & CountryResponse)[], any>;

const fetchers = {
  countries: getCountries,
  crops: getCropListByLocale,
  soilTypes: getSoilTypeListByLocale,
  conductionSystems: getConductionSystemByLocale,
  productionModes: getProductionModeListByLocale,
  jobTitles: getJobTitleListByLocale,
  pathogenTypes: getPathogenTypeListByLocale,
  typologies: getTypologyListByLocale,
  pests: getAllPests,
  diseases: getAllDiseases,
  weeds: getAllWeeds,
  growthStages: getGrowthStagesByLocale,
  irrigationSystem: getIrrigationSystemDataResponseList,
};

type Fetchers<T = typeof fetchers> = { [K in keyof T]: Unpacked<ThenArg<T[K]>> };

type FixedValuesState = {
  [K in FixedValues]?: {
    byId: Record<string, Fetchers[K]>;
    list: Fetchers[K][];
  };
};

export class FixedValuesService extends Service<FixedValuesState> {
  static serviceName = 'FixedValues';
  private promises: { [K in FixedValues]?: PromiseLike<void> } = {};

  readonly getName: (type: FixedValues, id: string) => string | undefined;
  readonly get: <T extends Fetchers[K], K extends FixedValues>(type: K, id: string) => T | undefined;
  readonly getAll: <T extends Fetchers[K], K extends FixedValues>(type: K) => T[] | undefined;
  readonly getAsync: <T extends Fetchers[K], K extends FixedValues>(type: K, id: string) => PromiseLike<T | undefined>;
  readonly getAllAsync: <T extends Fetchers[K], K extends FixedValues>(type: K) => PromiseLike<T[]>;

  constructor(init: ServiceInit) {
    super(init);
    const K_FETCHED = `${this.$name}.fetched`;
    const i18nService = this.$ctx.get(I18nService);
    this.state = getObject(this.$name, {});
    let fetched: { [x in FixedValues]?: number } = getObject(K_FETCHED, {});
    let lang = i18nService.state.language;

    const checkAsync = async (type: FixedValues) => {
      const now = Date.now();
      if ((fetched[type] || 0) < now - FETCH_TIMEOUT) {
        fetched[type] = now;
        localStorage.setItem(K_FETCHED, JSON.stringify(fetched));
        this.promises[type] = fetchers[type]({}).then(value => {
          this.setState({
            [type]: {
              byId: listToMap(value),
              list: value,
            },
          });
          localStorage.setItem(this.$name, JSON.stringify(this.state));
        });
      }
      return this.promises[type];
    };

    const check = (type: FixedValues) => {
      checkAsync(type);
      return this.state[type];
    };

    this.$onDispose = i18nService.subscribe(({ language }: I18nState) => {
      if (language !== lang) {
        lang = language;
        const old = Object.keys(fetched);
        fetched = {};
        old.forEach(check);
      }
    });

    this.get = (type, id) => check(type)?.byId[id] as any;
    this.getName = (type, id) => this.get(type, id)?.name;
    this.getAll = type => check(type)?.list as any;
    this.getAsync = (type, id) => checkAsync(type).then(() => this.state[type]?.byId[id] as any);
    this.getAllAsync = type => checkAsync(type).then(() => this.state[type]?.list as any[]);
  }
}
