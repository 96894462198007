/**
 * Auto-generated, do not edit, it will be overwritten
 * https://github.com/porfirioribeiro/openapi2ts
 */
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { request, ResponsePromise } from '..';
import * as types from './types';

export interface GetGrowthStagesByLocaleOptions {
  cropId?: string;
}

export const getGrowthStagesByLocale = (
  options: GetGrowthStagesByLocaleOptions,
): ResponsePromise<types.IdNameAndCropResponse[], GetGrowthStagesByLocaleOptions> =>
  request({
    module: 'commons',
    method: 'GET',
    uri: '/growthStage',
    query: { cropId: options.cropId },
    options,
  });

export const getGrowthStagesByLocaleKey = 'getGrowthStagesByLocale';

export const useGetGrowthStagesByLocale = <TData = types.IdNameAndCropResponse[]>(
  options: GetGrowthStagesByLocaleOptions,
  queryConfig?: Partial<UseQueryOptions<types.IdNameAndCropResponse[], unknown, TData>>,
) =>
  useQuery({
    queryFn: async () => getGrowthStagesByLocale(options),
    queryKey: [getGrowthStagesByLocaleKey, options.cropId],
    ...queryConfig,
  });

export interface GetGrowthStagesByLocalePageableOptions {
  pageSize?: number;
  pageNumber?: number;
}

export const getGrowthStagesByLocalePageable = (
  options: GetGrowthStagesByLocalePageableOptions,
): ResponsePromise<types.PageableResponseTranslationResponse, GetGrowthStagesByLocalePageableOptions> =>
  request({
    module: 'commons',
    method: 'GET',
    uri: '/growthStage/pageable',
    query: { pageSize: options.pageSize, pageNumber: options.pageNumber },
    options,
  });

export const getGrowthStagesByLocalePageableKey = 'getGrowthStagesByLocalePageable';

export const useGetGrowthStagesByLocalePageable = <TData = types.PageableResponseTranslationResponse>(
  options: GetGrowthStagesByLocalePageableOptions,
  queryConfig?: Partial<UseQueryOptions<types.PageableResponseTranslationResponse, unknown, TData>>,
) =>
  useQuery({
    queryFn: async () => getGrowthStagesByLocalePageable(options),
    queryKey: [getGrowthStagesByLocalePageableKey, options.pageSize, options.pageNumber],
    ...queryConfig,
  });
