/* eslint-disable react/no-multi-comp */
import React from 'react';

import cn from 'clsx';

import { wrapChildren, WrapOption } from '../utils/wrap';
import { ExComponent, ExComponentProps } from '../utils/types';
import { typographyBodyMedium, typographyTitleMedium } from '../mdc';

export type ListItemProps<T extends ExComponent = 'li'> = ExComponentProps<T> & {
  innerRef?: React.Ref<T>;
  selected?: boolean;
  activated?: boolean;
  isGoBack?: boolean;
};

interface GetListItemClassParameters {
  className?: string;
  selected?: boolean;
  activated?: boolean;
  isGoBack?: boolean;
}

export const getListItemClass = ({ className, selected, activated, isGoBack }: GetListItemClassParameters) =>
  cn('mdc-deprecated-list-item', className, {
    'mdc-deprecated-list-item--selected': selected,
    'mdc-deprecated-list-item--activated': activated,
    'mdc-deprecated-list-item--go-back': isGoBack,
  });

export function ListItem<T extends ExComponent = 'li'>({
  component: C,
  innerRef,
  className,
  children,
  selected,
  activated,
  isGoBack,
  ...props
}: ListItemProps<T>) {
  return (
    <C ref={innerRef} className={getListItemClass({ className, selected, activated, isGoBack })} {...props}>
      {children}
    </C>
  );
}

ListItem.defaultProps = {
  component: 'li',
};

interface ListItemTextProps {
  className?: string;
  children?: React.ReactNode;
}

export const ListItemText: React.FC<ListItemTextProps> = ({ className, children, ...props }) => (
  <span className={cn('mdc-deprecated-list-item__text', className)} {...props}>
    {children}
  </span>
);

interface ListItemPrimaryTextProps {
  className?: string;
  children?: React.ReactNode;
}

export const ListItemPrimaryText: React.FC<ListItemPrimaryTextProps> = ({ className, children, ...props }) => (
  <span className={cn('mdc-deprecated-list-item__primary-text', typographyTitleMedium, className)} {...props}>
    {children}
  </span>
);

interface ListItemSecondaryTextProps {
  className?: string;
  children?: React.ReactNode;
}

export const ListItemSecondaryText: React.FC<ListItemSecondaryTextProps> = ({ className, children, ...props }) => (
  <span className={cn('mdc-deprecated-list-item__secondary-text', typographyBodyMedium, className)} {...props}>
    {children}
  </span>
);

interface ListItemGraphicProps {
  children?: React.ReactNode;
  className?: string;
  wrap?: WrapOption;
}

export const ListItemGraphic: React.FC<ListItemGraphicProps> = ({ children, wrap, className, ...props }) =>
  wrapChildren(children, wrap, 'span', { className: cn('mdc-deprecated-list-item__graphic', className), ...props });

interface ListItemMetaProps {
  children?: React.ReactNode;
  className?: string;
  wrap?: WrapOption;
}

export const ListItemMeta: React.FC<ListItemMetaProps> = ({ children, wrap, className, ...props }) =>
  wrapChildren(children, wrap, 'span', { className: cn('mdc-deprecated-list-item__meta', className), ...props });

type SimpleListItemProps<T extends ExComponent = 'li'> = ListItemProps<T> & {
  text?: React.ReactNode;
  secondaryText?: React.ReactNode;
  graphic?: React.ReactNode;
  meta?: React.ReactNode;
};

export function SimpleListItem<T extends ExComponent = 'li'>({
  text,
  secondaryText,
  graphic,
  meta,
  children,
  ...rest
}: SimpleListItemProps<T>) {
  return (
    <ListItem {...(rest as any)}>
      {!!graphic && <ListItemGraphic wrap>{graphic}</ListItemGraphic>}
      {secondaryText ? (
        <ListItemText>
          <ListItemPrimaryText>{text}</ListItemPrimaryText>
          <ListItemSecondaryText>{secondaryText}</ListItemSecondaryText>
        </ListItemText>
      ) : (
        text
      )}
      {!!meta && <ListItemMeta>{meta}</ListItemMeta>}
      {children}
    </ListItem>
  );
}

SimpleListItem.defaultProps = {
  component: 'li',
};
