import { FirebaseOptions, initializeApp } from 'firebase/app';
import { browserLocalPersistence, browserPopupRedirectResolver, connectAuthEmulator, initializeAuth } from 'firebase/auth';
import { getStorage, connectStorageEmulator } from 'firebase/storage';

import { initializeAnalytics } from 'firebase/analytics';

import dev from './dev.json';
import prod from './prod.json';

export const firebaseConfig: FirebaseOptions = import.meta.env.VITE_FIREBASE_PROFILE === 'prod' ? prod : dev;

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = initializeAuth(firebaseApp, {
  persistence: browserLocalPersistence,
  popupRedirectResolver: browserPopupRedirectResolver,
});

export const firebaseFieldMapsStorage = getStorage(firebaseApp, `gs://${import.meta.env.VITE_FIREBASE_FIELD_MAPS_STORAGE}`);

if (import.meta.env.VITE_FIREBASE_AUTH_EMULATOR_HOST)
  connectAuthEmulator(firebaseAuth, `http://${import.meta.env.VITE_FIREBASE_AUTH_EMULATOR_HOST}`);

if (import.meta.env.VITE_FIREBASE_STORAGE_EMULATOR_HOST)
  connectStorageEmulator(
    firebaseFieldMapsStorage,
    `${import.meta.env.VITE_FIREBASE_STORAGE_EMULATOR_HOST}`,
    import.meta.env.VITE_FIREBASE_STORAGE_EMULATOR_PORT,
  );

export const firebaseAnalytics = initializeAnalytics(firebaseApp, { config: { send_page_view: false } });
