import React from 'react';
import { IcBackoffice } from '@agroop/icons';
import { UserAppName } from '../services/Auth';
import farmooIcon from '../images/Farmoo.svg';
import { linkOrOrigin } from './url';

export const farmooUrl = linkOrOrigin(import.meta.env.VITE_APP_URL_FARMOO || '/farmoo');
export const backofficeUrl = linkOrOrigin(import.meta.env.VITE_APP_URL_BACKOFFICE || '/backoffice');
export const accountsUrl = linkOrOrigin(import.meta.env.VITE_APP_URL_ACCOUNTS || '/accounts');
export const loginUrl = `${accountsUrl}/login`;
export const createAccountUrl = `${accountsUrl}/confirmNewAccount`;
export const goToLogin = () => location.replace(loginUrl);

export const appLocations: Record<UserAppName, string> = {
  ACCOUNTS: accountsUrl,
  FARMOO: farmooUrl,
  BACKOFFICE: backofficeUrl,
};

export const appIcons: Record<UserAppName, React.ReactNode> = {
  ACCOUNTS: null,
  FARMOO: <img src={farmooIcon} alt="Farmoot" />,
  BACKOFFICE: <IcBackoffice width={48} height={48} color="#0747A6" />,
};

export const appNames: Record<UserAppName, string> = {
  ACCOUNTS: 'Accounts',
  FARMOO: 'Farmoot',
  BACKOFFICE: 'Backoffice',
};
