/* eslint-disable no-multi-assign */
import { useContext, useEffect, useRef } from 'react';
import { FieldState, FormContext, FormCtx, SetValueOptions, ValidationFunction } from './types';
import { FieldValidationName, FieldValidations } from './FieldValidations';
import defaults from './defaults';
import { transform } from './utils';

export type FieldChangeHandler<T> = (value: T, field: FieldState, form: FormCtx) => void;

export type BaseFieldOptions<T, P> = P & {
  name: string;
  label?: string;
  value?: T;
  defaultValue?: any;
  required?: boolean;
  onChange?: FieldChangeHandler<T>;
  mapToData?: (value: any) => any;
  mapFromData?: (value: any) => any;
  validations?: (ValidationFunction | FieldValidationName)[];
  selfDestroy?: boolean;
};

export interface UseFieldResult<T, P> {
  value: T | undefined;
  label?: string;
  field: FieldState;
  form: FormCtx;
  setValue: (newValue: any, options?: SetValueOptions) => void;
  props: Omit<P, 'name' | 'onChange'>;
}

export function useField<T extends any, P extends {}>(o: BaseFieldOptions<T, P>): UseFieldResult<T, P> {
  const {
    name,
    selfDestroy = true,
    value: oValue,
    defaultValue: oDefaultValue,
    validations: oValidations,
    label,
    mapFromData,
    mapToData,
    onChange,
    required,
    ...props
  } = o;
  const form = useContext(FormContext);

  const prevOptions = useRef<BaseFieldOptions<T, P>>();
  let value: T | undefined;
  let field: FieldState;
  const prev = prevOptions.current;
  let defaultValue: T;
  if (!prev || prev.name !== name) {
    if (prev) form.unregisterField(prev.name);
    const formDataValue = form.getValue(name);
    if (oValue !== undefined) {
      value = defaultValue = oValue;
    } else if (oDefaultValue !== undefined && formDataValue === undefined) {
      value = defaultValue = oDefaultValue;
    } else {
      value = formDataValue;
    }

    // map string validations to functions
    const validations =
      oValidations &&
      oValidations.map(validation =>
        typeof validation === 'string'
          ? (v: any, n: string, formCtx: FormCtx): false | string =>
              FieldValidations[validation](v, n, formCtx) ? false : defaults.getMessage(validation)
          : validation,
      );
    field = {
      name,
      validations,
      required: !!required,
      error: false,
      valid: false,
      dirty: false,
      focused: false,
    };
  } else {
    field = form.fields[name];
    const v = form.getValue(name);
    // @ts-ignore
    if (value !== v) value = transform(mapFromData, v);
  }

  useEffect(() => {
    form.registerField(field, defaultValue);
    return selfDestroy ? () => form.unregisterField(name) : undefined;
  }, [name, selfDestroy]);

  if (prev && prev.required !== required) form.setFieldState(name, { required, error: false });
  // this value should be memoized
  useEffect(() => {
    if (prev && prev.defaultValue !== oDefaultValue) form.setValue(name, oDefaultValue);
  }, [prev?.defaultValue, oDefaultValue]);

  prevOptions.current = o;

  return {
    field,
    form,
    value,
    label,
    props: props as any,
    setValue: (newValue: any, options?: SetValueOptions) => {
      const v = transform(mapToData, newValue);
      if (value === v) return;
      form.setValue(name, v, options);
      if (onChange) onChange(v, field, form);
      if (options && options.validate) form.validateField(name, newValue);
    },
  };
}
