import React from 'react';
import cn from 'clsx';
import { ExComponent, ExComponentProps } from '../utils/types';

export type ListProps<T extends ExComponent = 'ul'> = ExComponentProps<T> & {
  nonInteractive?: boolean;
  dense?: boolean;
  avatarList?: boolean;
  twoLine?: boolean;
  padded?: boolean;
};

function ListF<T extends ExComponent = 'ul'>(
  { component: C = 'ul', className, children, nonInteractive, dense, avatarList, twoLine, padded, ...props }: ListProps<T>,
  ref: React.Ref<T>,
) {
  return (
    <C
      ref={ref}
      className={cn('mdc-deprecated-list', className, {
        'mdc-deprecated-list--non-interactive': nonInteractive,
        'mdc-deprecated-list--dense': dense,
        'mdc-deprecated-list--avatar-list': avatarList,
        'mdc-deprecated-list--two-line': twoLine,
        'mdc-deprecated-list--padded': padded,
      })}
      {...props}
    >
      {children}
    </C>
  );
}

export const List = React.forwardRef(ListF) as typeof ListF;
