/* eslint-disable no-nested-ternary,spaced-comment,no-multi-assign */
import { useEffect, useState, useRef } from 'react';

type ScrollAxis = 'x' | 'y';

export interface ScrollState {
  /** The direction of scroll since last scroll forward: (down, right) back: (up, left) */
  dir: 'none' | 'forward' | 'back';
  /**
   * Where is the scroll at (edge)
   * undefined: element is not scrollable
   * start: scroll is at start (top, left)
   * scrolling: scroll is not at any edge
   * end: scroll is at end (bottom, right)
   */
  at?: 'start' | 'scrolling' | 'end' | undefined;
  /** mostly for internal use, represents the last scroll position */
  last: number;
}

/** The default scroll state of an element */
export const defaultScrollState: ScrollState = {
  dir: 'none',
  last: 0,
};

export type ScrollManagerEntry = { target: HTMLElement; state: ScrollState };
export type ScrollManagerCallback = (entries: ScrollManagerEntry[]) => void;
export type ScrollManagerShape = ReturnType<typeof ScrollManager>;

/**
 * This utility keeps track of scroll direction and edge position
 * @param callback
 * @param axis
 * @constructor
 */
export function ScrollManager(callback: ScrollManagerCallback, axis: ScrollAxis = 'y') {
  let handledNodes: Element[] = [];
  let scrollStates: ScrollState[] = [];
  const resizeObserver = new ResizeObserver(handleResize);

  function mapToState(target: HTMLElement): ScrollState | false {
    const index = handledNodes.indexOf(target);

    const oldState = scrollStates[index];
    const newState = (scrollStates[index] = getScrollState(target, oldState.last, axis));

    return newState.at !== oldState.at || newState.dir !== oldState.dir ? newState : false;
  }

  function handleResize(entries: ResizeObserverEntry[]) {
    const states = entries.reduce<ScrollManagerEntry[]>((acc, value) => {
      const target = value.target as HTMLElement;
      const state = mapToState(target);
      return state ? acc.concat({ target, state }) : acc;
    }, []);
    if (states.length) callback(states);
  }

  function handleScroll(e: WheelEvent) {
    const target = e.currentTarget as HTMLElement;
    const state = mapToState(target);
    if (state) callback([{ state, target }]);
  }

  return {
    observe(target: HTMLElement) {
      const scrollState = getScrollState(target, 0, axis);
      handledNodes.push(target);
      scrollStates.push(scrollState);
      resizeObserver.observe(target);
      target.addEventListener('scroll', handleScroll, { passive: true });
      return scrollState;
    },
    unobserve(target: HTMLElement) {
      const index = handledNodes.indexOf(target);
      handledNodes.splice(index, 1);
      scrollStates.splice(index, 1);
      resizeObserver.unobserve(target);
      target.removeEventListener('scroll', handleScroll);
    },
    disconnect() {
      resizeObserver.disconnect();
      handledNodes = [];
      scrollStates = [];
    },
  };
}

/**
 * Get the current ScrollState of a element
 * @param element
 * @param last
 * @param axis
 */
export function getScrollState(element: HTMLElement, last: number, axis: ScrollAxis): ScrollState {
  const vertical = axis === 'y';
  const start = vertical ? element.scrollTop : element.scrollLeft;
  const scroll = vertical ? element.scrollHeight : element.scrollWidth;
  const offset = vertical ? element.offsetHeight : element.offsetWidth;

  return {
    last: start,
    dir: start ? (start > last ? 'forward' : 'back') : 'none',
    at: scroll !== offset ? (start === 0 ? 'start' : scroll - (start + offset) <= 2.5 ? 'end' : 'scrolling') : undefined,
  };
}

export type GlobalScrollManagerCallback = (state: ScrollState) => void;

/**
 * This utility keeps track of vertical scrolling state on the window
 * @constructor
 */
function WindowScrollManager() {
  const target = (document.scrollingElement as HTMLElement) || document.documentElement;
  let scrollState: ScrollState = getScrollState(target, 0, 'y');
  const listeners: GlobalScrollManagerCallback[] = [];
  new ResizeObserver(handle).observe(target);
  window.addEventListener('scroll', handle, { passive: true });

  function handle() {
    const newState = getScrollState(target, scrollState.last, 'y');
    if (newState.at !== scrollState.at || newState.dir !== scrollState.dir) listeners.forEach(l => l(newState));
    scrollState = newState;
  }

  return {
    getScrollState() {
      return scrollState;
    },
    subscribe(fn: GlobalScrollManagerCallback) {
      listeners.push(fn);
      return () => {
        listeners.splice(listeners.indexOf(fn), 1);
      };
    },
  };
}

/** WindowScrollManager instance */
export const windowScrollManager = WindowScrollManager();

/** Get the current scroll state and subscribe to changes */
export function useWindowScrollManager() {
  const [state, setState] = useState(windowScrollManager.getScrollState);
  useEffect(() => windowScrollManager.subscribe(setState), []);
  return state;
}

function GlobalScrollManager(axis: ScrollAxis) {
  const listeners = new WeakMap<HTMLElement, GlobalScrollManagerCallback>();
  const scrollManager = ScrollManager(entries => entries.forEach(e => listeners.get(e.target)!(e.state)), axis);

  return {
    subscribe(element: HTMLElement, fn: GlobalScrollManagerCallback) {
      listeners.set(element, fn);
      const scrollState = scrollManager.observe(element);
      if (scrollState.at !== defaultScrollState.at || scrollState.dir !== defaultScrollState.dir) fn(scrollState);
      return () => {
        scrollManager.unobserve(element);
        listeners.delete(element);
      };
    },
  };
}

export const horizontalScrollManager = GlobalScrollManager('x');
export const verticalScrollManager = GlobalScrollManager('y');

export function useHorizontalScrollManager<T extends HTMLElement>(): [ScrollState, React.RefObject<T>] {
  const [state, setState] = useState(defaultScrollState);
  const ref = useRef<T>(null);
  useEffect(() => horizontalScrollManager.subscribe(ref.current!, setState), []);
  return [state, ref];
}

export function useVerticalScrollManager<T extends HTMLElement>(): [ScrollState, React.RefObject<T>] {
  const [state, setState] = useState(defaultScrollState);
  const ref = useRef<T>(null);
  useEffect(() => verticalScrollManager.subscribe(ref.current!, setState), []);
  return [state, ref];
}
