import React, { FC, HTMLProps, forwardRef } from 'react';

import cn from 'clsx';

import { typographyHeadlineSmall } from '../mdc';

export interface TopAppBarRowProps extends HTMLProps<HTMLDivElement> {}

export const TopAppBarRow: FC<TopAppBarRowProps> = ({ children, className, ...otherProps }) => (
  <div className={cn(className, 'mdc-top-app-bar__row')} {...otherProps}>
    {children}
  </div>
);

export interface TopAppBarSectionProps extends HTMLProps<HTMLElement> {
  align?: 'start' | 'end';
}

export const TopAppBarSection: FC<TopAppBarSectionProps> = ({ align, className, children, ...otherProps }) => (
  <section
    className={cn(className, 'mdc-top-app-bar__section', {
      [`mdc-top-app-bar__section--align-${align}`]: align,
    })}
    {...otherProps}
  >
    {children}
  </section>
);

export interface TopAppBarTitleProps extends HTMLProps<HTMLSpanElement> {}

export const TopAppBarTitle = forwardRef<HTMLSpanElement, TopAppBarTitleProps>(({ children, className, ...otherProps }, ref) => (
  <span ref={ref} className={cn(className, 'mdc-top-app-bar__title', typographyHeadlineSmall)} {...otherProps}>
    {children}
  </span>
));
