import React from 'react';
import cn from 'clsx';

interface ListDividerProps {
  className?: string;
  children?: React.ReactNode;
  padded?: boolean;
  inset?: boolean;
  spaced?: boolean;
  group?: boolean;
}

export const ListDivider: React.FC<ListDividerProps> = ({ className, children, padded, inset, spaced, group, ...props }) =>
  React.createElement(
    group ? 'hr' : 'li',
    {
      className: cn('mdc-deprecated-list-divider', className, {
        'mdc-deprecated-list-divider--padded': padded,
        'mdc-deprecated-list-divider--inset': inset,
        'mdc-deprecated-list-divider--spaced': spaced,
      }),
      ...props,
    },
    children,
  );
