import React from 'react';
import { useService } from 'rc-service';
import { UiService } from '@agroop/app-shell';
import Dialog from '@agroop/ui/lib/Dialog';
import { Snackbar } from '@agroop/ui/lib/Snackbar';
import { breakTextNode } from '@agroop/ui/lib/utils/break';
import { Popup } from '../services/UiService';

interface UiHelperProps {
  buttons: Record<string, string>;
}

export function UiHelper({ buttons }: UiHelperProps) {
  const ui = useService(UiService);

  const popupOpen = ui.state.popupOpen;
  const popup = popupOpen ? ui.state.popups[0] : ({} as Popup);
  if (popup.buttons)
    popup.buttons = popup.buttons.map(button => ({
      ...button,
      text: button.text || buttons[button.name],
    }));

  const snackbar = ui.state.snackbars[0];
  return (
    <>
      <Dialog open={popupOpen} {...popup}>
        {breakTextNode(popup.message)}
      </Dialog>
      <Snackbar open={!!snackbar} {...snackbar} />
    </>
  );
}
