import cn from 'clsx';
import React from 'react';
import { GetItemPropsOptions } from 'downshift';
import { Instance } from '@popperjs/core';
import { SelectOptionType } from './Select';
import { List, ListItem, ListItemMeta, ListItemText } from '../ItemList';
import { ListProps } from '../ItemList/List';
import { getPopper } from './utils';
import Checkbox from '../Checkbox/Checkbox';
import { MenuSurfaceShow } from '../MenuSurface';
import './Select.scss';

export type IconControl = React.ReactElement<Element> | ((item?: any) => React.ReactElement<Element> | null);

interface SelectMenuProps {
  menuProps: ListProps;
  options: SelectOptionType[];
  twoLine?: boolean;
  highlightedIndex: number | null;
  selectedItem?: any;
  getItemProps: (options: GetItemPropsOptions<any>) => any;
  valueKey: string;
  labelKey: string;
  itemToString: (item: any, key: string) => string;
  renderItem?: (item: any, index: number) => React.ReactNode;
  optionIcon?: IconControl;
  target: React.RefObject<HTMLElement>;
  multi?: boolean;
}

export class SelectMenu extends React.PureComponent<SelectMenuProps> {
  popper?: Instance;

  setMenuRef: React.Ref<HTMLDivElement> = r => {
    if (this.popper) this.popper.destroy();
    if (r && this.props.target.current) this.popper = getPopper(this.props.target.current, r);
  };

  componentDidUpdate(prevProps: SelectMenuProps) {
    if (this.popper && prevProps.options !== this.props.options) this.popper.update();
  }

  renderItem = (item: any, index: number) => {
    const { getItemProps, highlightedIndex, valueKey, labelKey, itemToString, renderItem, optionIcon, selectedItem, multi } = this.props;

    const activated = highlightedIndex === index;
    const selected = multi && Array.isArray(selectedItem) ? selectedItem.includes(item) : selectedItem === item;
    return (
      <SelectMenuItem
        key={item[valueKey] || index}
        getItemProps={getItemProps}
        renderItem={renderItem || (it => <ListItemText>{itemToString(it, labelKey)}</ListItemText>)}
        selected={selected}
        activated={activated}
        optionIcon={optionIcon}
        item={item}
        index={index}
        multi={multi}
      />
    );
  };

  render() {
    const { menuProps, options } = this.props;

    return (
      <MenuSurfaceShow show className="agSelect__menu" ref={this.setMenuRef}>
        <List nonInteractive {...menuProps}>
          {options.map(this.renderItem)}
        </List>
      </MenuSurfaceShow>
    );
  }
}

interface SelectMenuItemProps {
  getItemProps: (options: GetItemPropsOptions<any>) => any;
  renderItem: (item: any, index: number) => React.ReactNode;
  optionIcon?: IconControl;
  item: any;
  index: number;
  selected?: boolean;
  activated: boolean;
  multi?: boolean;
}

export function SelectMenuItem({ optionIcon, item, index, getItemProps, selected, activated, renderItem, multi }: SelectMenuItemProps) {
  const optionIconElement = typeof optionIcon === 'function' ? optionIcon(item) : optionIcon;

  return (
    <ListItem
      {...getItemProps({
        item,
        selected: !activated && selected,
        activated,
      } as any)}
    >
      {optionIconElement &&
        React.cloneElement(optionIconElement, {
          className: cn('agSelect-menu__graphic', optionIconElement.props.className),
        })}
      {renderItem(item, index)}
      {multi && (
        <ListItemMeta wrap>
          <Checkbox className="mdc-deprecated-list-item__meta" checked={selected} />
        </ListItemMeta>
      )}
    </ListItem>
  );
}
