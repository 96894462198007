/* eslint-disable no-use-before-define */
import { MapErrorsFn, OnValidationFailedFn, OnSubmitErrorFn } from './types';

interface Defaults {
  getMessage: (id: string) => string;
  mapErrors: MapErrorsFn;
  onSubmitError: OnSubmitErrorFn;
  onValidationFailed: OnValidationFailedFn;
}

const getMessage = (id: string): string => (id === 'required' ? 'Required field' : '');
const onValidationFailed: OnValidationFailedFn = () => {};
const onSubmitError: OnSubmitErrorFn = () => {};

export const mapErrors: MapErrorsFn = (result: any /* , form: FormCtx */) =>
  result instanceof Array ? result : Object.keys(result).map(name => ({ name, error: result[name] }));

const defaults: Defaults = { getMessage, mapErrors, onValidationFailed, onSubmitError };

export const setDefaults = (newDefaults: Partial<Defaults>) => Object.assign(defaults, newDefaults);

export default defaults;
