import React from 'react';
import { ShowOf, ShowOfComponentProps } from 'react-showof';
import Portal from '../Portal';

type MenuSurfaceProps = {
  className?: string;
  children: (() => React.ReactNode) | React.ReactNode;
};

export const MenuSurface = React.forwardRef<HTMLDivElement, ShowOfComponentProps<MenuSurfaceProps>>((props, ref) => {
  return (
    <Portal>
      <div
        onTransitionEnd={props.onTransitionEnd}
        ref={ref}
        className={`ag-menu-surface ag-menu-surface--${props.state} ${props.className ?? ''}`}
      >
        {props.children instanceof Function ? props.children() : props.children}
      </div>
    </Portal>
  );
});

export const MenuSurfaceShow = React.forwardRef<HTMLDivElement, MenuSurfaceProps & { show: boolean }>(
  ({ children, show, className }, ref) => (
    <ShowOf when={show} render={MenuSurface} ref={ref} className={className}>
      {children}
    </ShowOf>
  ),
);
