import React, { Reducer, useReducer, useRef, createElement, useEffect, MutableRefObject } from 'react';
import { immDelete, immGet, immSet } from '@agroop/common/utils/immutable';
import shallowEqual from '@agroop/common/utils/shallowEqual';
import isPromise from 'is-promise';
import { arrayWithoutIndex } from '@agroop/common/utils/array';
import { FieldState, FormContext, FormCtx, FormSubmitFn, SetValueOptions, UseFormOptions } from './types';
import defaults from './defaults';
import { findFirst, getDataSet, updateField } from './utils';

export type FormActions<FD> =
  | { type: 'set'; state: Partial<FormCtx<FD>> }
  | { type: 'setFormData'; formData: Partial<FD> }
  | { type: 'registerField'; field: FieldState; value: any }
  | { type: 'unregisterField'; name: string }
  | { type: 'setFieldState'; name: string; fieldState: Partial<FieldState> }
  | { type: 'setValue'; name: string; value: any; markDirty?: boolean }
  | { type: 'setValues'; values: { [key: string]: any }; markDirty?: boolean }
  | { type: 'deleteValue'; name: string }
  | { type: 'reset' };

function reducer<FD extends {}>(state: FormCtx<FD>, action: FormActions<FD>): FormCtx<FD> {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.state };
    case 'setFormData':
      return { ...state, formData: action.formData as FD, initialFormData: action.formData as FD };
    case 'registerField':
      return {
        ...state,
        formData: action.value !== undefined ? immSet(state.formData, action.field.name, action.value) : state.formData,
        fields: {
          ...state.fields,
          [action.field.name]: action.field,
        },
      };
    case 'unregisterField':
      return {
        ...state,
        fields: immDelete(state.fields, [action.name]),
      };
    case 'setFieldState':
      return {
        ...state,
        dirty: action.fieldState.dirty || state.dirty,
        fields: {
          ...state.fields,
          [action.name]: Object.assign({}, state.fields[action.name], action.fieldState),
        },
      };
    case 'setValue':
      return updateField(state, action.name, action.value, !!action.markDirty);
    case 'setValues':
      return Object.keys(action.values).reduce((acc: any, name) => updateField(acc, name, action.values[name], !!action.markDirty), state);
    case 'deleteValue':
      return { ...state, formData: immDelete(state.formData, action.name) };
    case 'reset':
      return { ...state, formData: state.initialFormData, dirty: false };
    default:
      return state;
  }
}

type FormInitOptions<FD extends {}> = { options: UseFormOptions<FD>; formRef: MutableRefObject<FormReducerResult<FD> | undefined> };

function initializer<FD extends {}>({ options, formRef }: FormInitOptions<FD>): FormCtx<FD> {
  function getState() {
    return formRef.current![0];
  }

  function dispatch(a: FormActions<FD>) {
    if (formRef.current) formRef.current[1](a);
  }

  function setState(state: Partial<FormCtx<FD>>) {
    dispatch({ type: 'set', state });
  }

  const setFieldState = (name: string, fieldState: Partial<FieldState>) =>
    dispatch({
      type: 'setFieldState',
      name,
      fieldState,
    });

  function doSubmit(onSubmit: FormSubmitFn, extraFormData: Partial<FD> = {}) {
    const state = getState();
    if (state.submitting) return null;
    setState({ submitting: true });

    const invalidFields = Object.keys(state.fields).filter(name => state.validateField(name));
    const invalid = invalidFields.length > 0;

    if (invalid) {
      setState({ submitting: false });
      (state.options.onValidationFailed || defaults.onValidationFailed)(invalidFields, state);
    }
    if (onSubmit && !invalid) {
      const formData = Object.assign({}, state.formData, extraFormData) as FD;
      const p = onSubmit(formData);
      if (isPromise(p)) {
        return p.then(
          data => {
            if (formRef.current) {
              setState({ dirty: false, submitting: false });
              const state1 = getState();
              if (state1.options.onAfterSubmit) state1.options.onAfterSubmit(data);
              if (state1.options.resetAfterSubmit) state1.reset();
            }
            return data;
          },
          err => {
            setState({ submitting: false });
            if (err) {
              const state1 = getState();
              (state1.options.onSubmitError || defaults.onSubmitError)(err, state1);
              (state1.options.mapErrors || defaults.mapErrors)(err, state1).forEach(error =>
                state1.setFieldState(error.name, {
                  dirty: true,
                  valid: false,
                  error: error.error,
                }),
              );
            }
          },
        );
      }
      setState({ submitting: false });
    }
    return null;
  }

  function getValue(name: string, defaultValue?: any) {
    return immGet(getState().formData, name, defaultValue);
  }

  const setValue = (name: string, value: any, { markDirty = true }: SetValueOptions = {}) =>
    dispatch({
      type: 'setValue',
      name,
      value,
      markDirty,
    });

  function array(name: string) {
    const arr = immGet(getState().formData, name);
    return Array.isArray(arr) ? arr : [];
  }

  return {
    options,
    formData: (options.formData || {}) as FD,
    initialFormData: (options.formData || {}) as FD,
    fields: {},
    dirty: false,
    submitting: false,
    // field registration
    registerField: (field: FieldState, value: any) => dispatch({ type: 'registerField', field, value }),
    unregisterField: (name: string) => dispatch({ type: 'unregisterField', name }),
    setFieldState,
    setFormData(formData) {
      dispatch({ type: 'setFormData', formData });
    },
    getValue,
    setValue,
    setValues: (values: { [key: string]: any }, { markDirty = true }: SetValueOptions = {}) =>
      dispatch({ type: 'setValues', values, markDirty }),
    deleteValue: (name: string) => dispatch({ type: 'deleteValue', name }),
    array,
    arrayMap: (name: string, callback: (path: string, index: number, value: any) => any) => {
      return array(name).map((value, index) => callback(`${name}.${index}`, index, value));
    },
    arrayPush: (name: string, obj?: any) => {
      const data = array(name).concat(obj);
      setValue(name, data);
      return data.length - 1;
    },
    arrayRemove: (name, index) => setValue(name, arrayWithoutIndex(array(name), index)),
    objectMap: (name: string, callback: (path: string, key: string, value: any) => any) => {
      const set = getValue(name, {}) as any;
      return Object.keys(set).map(key => callback(`${name}.${key}`, key, set[key]));
    },
    // submit
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      doSubmit(getState().options.onSubmit, getDataSet());
    },
    submit: (extraFormData: Partial<FD> = {}) => doSubmit(getState().options.onSubmit, extraFormData),
    handleReset: (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      dispatch({ type: 'reset' });
    },
    reset: () => dispatch({ type: 'reset' }),
    // validations
    validateField: (name: string, value = getValue(name)) => {
      const form = getState();
      const { required, validations } = form.fields[name];
      let error: string | false = false;
      if (required && (value === undefined || value === null || value === '')) {
        error = defaults.getMessage('required');
      } else if (validations && value) {
        error = findFirst(validations, validation => {
          // Todo async validations
          return validation(value, name, form);
        });
      }

      const oldError = form.fields[name].error;
      if (error) {
        if (error !== oldError) setFieldState(name, { error, dirty: true });
      } else if (oldError) setFieldState(name, { error: false });

      return error;
    },
    handleInputFocus: e => setFieldState(e.currentTarget.name, { focused: true }),
    handleInputBlur: e => setFieldState(e.currentTarget.name, { focused: false }),
    Provider: ({ children, ...props }) => {
      const form = getState();
      return createElement(
        FormContext.Provider,
        { value: form },
        createElement('form', { ...props, onSubmit: form.handleSubmit, onReset: form.handleReset }, children),
      );
    },
  };
}

type FormReducerResult<FD extends {}> = [FormCtx<FD>, (a: FormActions<FD>) => void];

export function useForm<FD extends {}>(options: UseFormOptions<FD>): FormCtx<FD> {
  const formRef = useRef<FormReducerResult<FD>>();
  const reduced = useReducer<Reducer<FormCtx<FD>, FormActions<FD>>, FormInitOptions<FD>>(
    reducer,
    {
      options,
      formRef,
    },
    initializer,
  );
  useEffect(() => () => (formRef.current = undefined), []);
  const [form, dispatch] = reduced;
  if (formRef.current) {
    // update form

    // hot wire the new options
    form.options = options;

    // update formData if changed
    if (options.formData && !shallowEqual(options.formData, form.initialFormData))
      dispatch({ type: 'setFormData', formData: options.formData });
  }

  formRef.current = reduced;

  return form;
}

export const formInvalidParams = (title: string, message: React.ReactNode, fields: Record<string, string>) =>
  Promise.reject({
    code: 422,
    title,
    message,
    fields: Object.entries(fields).map(([name, error]) => ({ name, error })),
  });
