import React, { useEffect, useState } from 'react';

import cn from 'clsx';

import { themeBg } from '@agroop/ui/lib/mdc';
import { useElementSize } from '@agroop/common/utils/useElementSize';

import styles from './SidebarFooter.module.scss';
import { useSidebarCtx } from './Sidebar';

interface SidebarFooterProps {
  children: React.ReactNode;
  position?: 'sticky' | 'fixed';
  className?: string;
  backgroundColor?: string;
}

export function SidebarFooter({ children, position, className, backgroundColor }: SidebarFooterProps) {
  const sidebar = useSidebarCtx();
  const [overrideScroll, setOverrideScroll] = useState(false);

  const [ref, size] = useElementSize();

  useEffect(() => {
    const resizeObserver = new ResizeObserver(_ => {
      const sidebarHeight = sidebar.ref.current?.clientHeight;
      const childrenHeight = Array.from(sidebar.ref.current?.childNodes ?? [])
        .map(node => (node as HTMLElement).clientHeight)
        .reduce((acc, value) => acc + value, 0);

      if (!size || !size.height || !sidebarHeight) return;

      setOverrideScroll(childrenHeight + (sidebar.scroll.at ? -size.height : 0) + size.height > sidebarHeight);
    });

    if (sidebar.ref.current) {
      resizeObserver.observe(sidebar.ref.current);
    }

    return () => {
      if (sidebar.ref.current) {
        resizeObserver.unobserve(sidebar.ref.current);
      }
    };
  }, [sidebar.ref, size, sidebar.scroll.at]);

  return (
    <footer
      ref={ref}
      className={cn(
        styles.root,
        {
          [styles.scrolled]: sidebar.scroll.at && sidebar.scroll.at !== 'end',
          [themeBg]: !backgroundColor,
        },
        className,
      )}
      style={{
        position: position === 'fixed' && (sidebar.scroll.at || overrideScroll) ? 'sticky' : position,
        bottom: position && 0,
        backgroundColor,
      }}
      data-at={sidebar.scroll.at}
    >
      {children}
    </footer>
  );
}
