import { InternalValidationFunction } from './types';

export type FieldValidationName = 'email' | 'password' | 'urlSafe' | 'double' | 'httpSafe';

export const FieldValidations: { [name in FieldValidationName]: InternalValidationFunction } = {
  email: value =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
      value,
    ),
  password: value => value.length >= 6,
  urlSafe: value => value === escape(value),
  double: value => /^-?\d+(?:[.]\d*?)?$/i.test(value),
  // eslint-disable-next-line no-useless-escape
  httpSafe: value => /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i.test(value),
};
