/**
 * Auto-generated, do not edit, it will be overwritten
 * https://github.com/porfirioribeiro/openapi2ts
 */
export interface Security {
  id: string;
  scopes?: string[];
}

export interface StringMap {
  [name: string]: string;
}
export interface ParamsMap {
  [name: string]: any;
}

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export type RequestParams<TRequestOptions> = {
  module?: string;
  method: HttpMethod;
  uri: string;
  path?: ParamsMap;
  query?: ParamsMap;
  formData?: ParamsMap;
  header?: ParamsMap;
  security?: Security[] | null;
  produces?: string[];
  consumes?: string[];
  body?: any;
  meta?: object;
  actionType?: string;
  options?: TRequestOptions;
};

export interface SecurityOptions {
  type: 'oauth2' | 'apiKey' | string; // Todo check all options
  name?: string;
  authorizationUrl?: string;
  flow?: 'implicit' | string; // Todo check all options
  scopes?: StringMap;
  in?: 'header' | string; // Todo check all options
}

export interface ApiResponse<TResponseData = any, TRequestOptions = any> {
  raw: Response;
  request: RequestParams<TRequestOptions>;
  data: TResponseData;
  error?: boolean;
}

export interface ReqOptions {
  modules: {
    [key: string]: {
      url: string;
      security: { [key: string]: SecurityOptions };
    };
  };
  defaults: {
    consumes?: string[] | null;
    produces?: string[] | null;
    security?: Security[] | null;
  };
  blobTypes: string[];
  applyAuth?: (p: RequestParams<any>, s: Security, securityOption: SecurityOptions) => PromiseLike<RequestParams<any>>;
  processRequest?: (p: RequestParams<any>) => RequestParams<any>;
  formatResponse?: (apiResponse: ApiResponse) => ApiResponse;
  handleError?: (error: Error) => void;
}

export const OpenApiAction = Symbol('OpenApiAction');

let options: ReqOptions = {
  modules: {
    accounts: {
      url: import.meta.env.VITE_API_URL_ACCOUNTS,
      security: { jwt: { type: 'apiKey', name: 'Authorization', in: 'header' } },
    },
    backoffice: {
      url: import.meta.env.VITE_API_URL_BACKOFFICE,
      security: { jwt: { type: 'apiKey', name: 'Authorization', in: 'header' } },
    },
    farmoo: {
      url: import.meta.env.VITE_API_URL_FARMOO,
      security: {
        jwt: { type: 'apiKey', name: 'Authorization', in: 'header' },
        xEntityId: { type: 'apiKey', name: 'X-entityId', in: 'header' },
      },
    },
    commons: {
      url: import.meta.env.VITE_API_URL_COMMONS,
      security: { jwt: { type: 'apiKey', name: 'Authorization', in: 'header' } },
    },
  },
  defaults: { consumes: ['application/json'], produces: ['application/json'], security: [{ id: 'JWT' }] },
  blobTypes: ['application/octet-stream', 'application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
};

type InitializeFn = (options: ReqOptions) => Partial<ReqOptions>;

export class ServiceError extends Error {
  data: any;
  code: number;
  request: RequestParams<any>;

  constructor(message: string, code: number, data: any, request: RequestParams<any>) {
    super(message);
    this.data = data;
    this.code = code;
    this.request = request;
  }
}

/** Initialize this api with some options */
export const initialize = (newOptions?: Partial<ReqOptions> | InitializeFn) => {
  if (newOptions)
    options = {
      ...options,
      ...(typeof newOptions === 'function' ? newOptions(options) : newOptions),
    };
};

/** Return a copy of the options object */
export const getOptions = (): ReqOptions => ({ ...options });

export interface ResponsePromise<TResponseData, TRequestOptions> extends PromiseLike<TResponseData> {
  type: typeof OpenApiAction;
  promise: Promise<ApiResponse<TResponseData, TRequestOptions>>;
  request: RequestParams<TRequestOptions>;
}

function mapFormData(formData: ParamsMap) {
  const fd = new FormData();
  Object.keys(formData).forEach(f => {
    let v = formData[f];
    if (v && v instanceof Object && !(v instanceof Date) && !(v instanceof File))
      v = new Blob([JSON.stringify(v)], { type: 'application/json' });
    fd.append(f, v);
  });
  return fd;
}

export const request = <TResponseData, TRequestOptions>(
  req: RequestParams<TRequestOptions>,
): ResponsePromise<TResponseData, TRequestOptions> => {
  req.module = req.module || 'default';
  const mod = options.modules[req.module];
  let resolveParams = Promise.resolve(req);
  const security = req.security !== null ? req.security || options.defaults.security : undefined;
  if (
    options.applyAuth && // Have specified a method to resolve auth
    req.security !== null && // Security is specified as null so don't try to use default security
    security?.length // Have some sort of security defined
  ) {
    resolveParams = security.reduce(
      (promise, sec) =>
        promise.then(newReq => options.applyAuth!(newReq, sec, mod.security[sec.id])) as Promise<RequestParams<TRequestOptions>>,
      resolveParams,
    );
  }
  const promise: Promise<ApiResponse<TResponseData, TRequestOptions>> = resolveParams.then(rp => {
    const params = options.processRequest ? options.processRequest(rp) : rp;
    let url = mod.url + req.uri;
    if (params.path) {
      url = Object.keys(params.path).reduce((u, p) => u.replace(`{${p}}`, params.path![p]), url);
    }
    if (params.query) {
      url += `?${Object.keys(params.query)
        .filter(q => typeof params.query![q] !== 'undefined')
        .map(q => {
          return Array.isArray(params.query![q])
            ? params.query![q].map((c: any) => `${q}=${encodeURIComponent(c)}`).join('&')
            : `${q}=${encodeURIComponent(params.query![q])}`;
        })
        .join('&')}`;
    }

    const produces = req.produces || options.defaults.produces;
    const consumes = req.consumes || options.defaults.consumes;
    const contentType = consumes ? consumes[0] : '';

    const headers: any = {
      Accept: produces ? produces.join(', ') : '',
      ...params.header,
    };

    if (contentType !== 'multipart/form-data') {
      headers['Content-Type'] = contentType;
    }

    const fetchOptions: RequestInit = {
      method: req.method,
      credentials: 'include',
      headers,
      body: params.body ? JSON.stringify(params.body) : params.formData ? mapFormData(params.formData) : undefined,
    };

    return fetch(url, fetchOptions).then(parseResponse.bind(null, req), parseError);
  }) as any;
  return {
    type: OpenApiAction,
    promise,
    request: req,
    then: (th, ca) => promise.then(r => th!(r.data), ca),
  };
};

function parseResponse(req: RequestParams<any>, res: Response) {
  // const format = response.ok ? parseResponse : formatServiceError;;
  const contentType = res.headers.get('content-type') || '';
  const contentLength = res.headers.get('content-length');

  let parse: Promise<any> = Promise.resolve();
  if (res.status !== 204 && contentLength !== '0') {
    if (contentType.indexOf('json') > -1) parse = res.json();
    else if (contentType.indexOf('text') > -1) parse = res.text();
    else if (options.blobTypes.includes(contentType)) parse = res.blob();
  }

  return parse.then(data => {
    let parsed: ApiResponse = { raw: res, data, request: req, error: !res.ok };
    if (options.formatResponse) parsed = options.formatResponse(parsed);
    if (res.ok) return Promise.resolve(parsed);

    const se = new ServiceError(data.message || res.statusText, res.status, parsed.data, req);
    if (options.handleError) options.handleError(se);
    return Promise.reject(se);
  });
}

function parseError(error: Error) {
  if (options.handleError) options.handleError(error);
  return Promise.reject(error);
}

export default initialize;
