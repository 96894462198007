import React from 'react';
import cn from 'clsx';

import Downshift, { StateChangeOptions } from 'downshift';
import { IcArrowFillDown } from '@agroop/icons';

import { stringContainsIgnoreCase } from '@agroop/common/utils/string';
import { MdcTextField } from '../TextField/components/MdcTextField';
import { Icon } from '../TextField/components/Icon';
import { FloatingLabel } from '../mdc/FloatingLabel';
import { LineRipple } from '../mdc/LineRipple';
import { HelperText } from '../TextField/components/HelperText';
import CircularProgress from '../CircularProgress/CircularProgress';

import { IconControl, SelectMenu } from './SelectMenu';
import { BaseSelectProps, itemToString, mapState, scrollIntoView } from './utils';
import { ListProps } from '../ItemList/List';
import { EllipsisScrollerInput } from '../EllipsisScroller/EllipsisScroller';

export type SelectOptionType = any;

export interface SelectProps extends BaseSelectProps {
  searchable?: boolean;
  clearable?: boolean;
  simpleValue?: boolean;
  // Options
  options?: SelectOptionType[];
  loadOptions?: () => PromiseLike<SelectOptionType[]>;
  onChange?: (option: any) => void;
  onSelect?: (option: any) => void;
  valueKey?: string;
  labelKey?: string;
  // Icons
  icon?: IconControl;
  selectIcon?: IconControl;
  optionIcon?: IconControl;
  menuProps?: ListProps;
}

export interface SelectState {
  options: SelectOptionType[];
  inputValue: string;
  filterValue: string;
  selectedItem: SelectOptionType | null;
  oldProps: SelectProps;
  loadingOptions: boolean;
}
const hasOwnProperty = Object.prototype.hasOwnProperty;

class Select extends React.Component<SelectProps, SelectState> {
  static defaultProps = {
    valueKey: 'id',
    labelKey: 'name',
    itemToString,
  };

  static getDerivedStateFromProps(props: SelectProps, prevState: SelectState): Partial<SelectState> | null {
    const optionsChanged = props.options !== prevState.oldProps.options;
    if (props.value !== prevState.oldProps.value || optionsChanged)
      return { ...mapState(optionsChanged ? props.options : prevState.options, props.value, props!), oldProps: props };
    return null;
  }

  target = React.createRef<HTMLInputElement>();
  input = React.createRef<HTMLInputElement>();

  constructor(props: SelectProps) {
    super(props);
    this.state = {
      options: [],
      inputValue: '',
      filterValue: '',
      selectedItem: null,
      oldProps: {},
      loadingOptions: !!this.props.loadOptions,
    };

    if (this.props.loadOptions) {
      this.props
        .loadOptions()
        .then(options => this.setState(Object.assign(mapState(options, this.props.value, this.props), { loadingOptions: false })));
    }
  }

  render() {
    const {
      className,
      label,
      disabled,
      focused,
      invalid,
      inline,
      box,
      icon,
      selectIcon = icon,
      optionIcon = icon,
      validationText,
      helpText,
      helpTextPersistent,
      required,
      onBlur,
      onFocus,
      searchable,
      clearable,
      valueKey,
      labelKey,
      value,
      name,
      menuProps,
    } = this.props;
    const { options, filterValue, loadingOptions } = this.state;

    const isObject = typeof options[0] === 'object' && options[0] !== null;

    const filteredOptions = searchable
      ? isObject
        ? options.filter(item => !filterValue || stringContainsIgnoreCase(item[labelKey!], filterValue))
        : options.filter(item => !filterValue || stringContainsIgnoreCase(item, filterValue))
      : options;

    return (
      <Downshift
        itemToString={this.itemToString}
        selectedItem={this.state.selectedItem}
        inputValue={this.state.inputValue}
        onStateChange={this.handleStateChange}
        defaultHighlightedIndex={value ? options.findIndex(o => o[valueKey!] === value[valueKey!]) : 0}
        scrollIntoView={scrollIntoView}
      >
        {({
          isOpen,
          highlightedIndex,
          selectedItem,
          getInputProps,
          getLabelProps,
          getMenuProps,
          getItemProps,
          openMenu,
          clearSelection,
        }) => {
          const selectIconElement = typeof selectIcon === 'function' ? selectIcon(selectedItem) : selectIcon;
          const hasValue = !!selectedItem;
          return (
            <div className={cn('mdc-text-field__wrapper', 'agSelect', { 'agSelect--clearable': clearable }, className)}>
              <MdcTextField
                disabled={disabled}
                focused={focused}
                invalid={invalid}
                box={box}
                inline={inline}
                leadingIcon={!!selectIconElement}
                trailingIcon
                onClick={() => openMenu()}
                ref={this.target}
              >
                {selectIconElement && <Icon icon={selectIconElement} pos="leading" />}
                <EllipsisScrollerInput>
                  <input
                    autoComplete="off"
                    {...getInputProps({
                      name,
                      className: 'mdc-text-field__input',
                      disabled,
                      onFocus: (e: React.FocusEvent<HTMLInputElement>) => (openMenu(), onFocus && onFocus(e)), // eslint-disable-line no-sequences
                      onBlur,
                      required,
                      readOnly: !searchable,
                      ref: this.input,
                    })}
                  />
                </EllipsisScrollerInput>
                <FloatingLabel active={isOpen || hasValue} {...getLabelProps()} required={required}>
                  {label}
                </FloatingLabel>
                {clearable && hasValue && (
                  <span className="agSelect__clear" onClick={() => clearSelection()}>
                    x
                  </span>
                )}
                <Icon
                  pos="trailing"
                  icon={
                    loadingOptions ? <CircularProgress width={16} style={{ color: 'var(--mdc-theme-primary)' }} /> : <IcArrowFillDown />
                  }
                />
                <LineRipple active={focused!} />
              </MdcTextField>

              {!disabled && isOpen && filteredOptions.length > 0 && (
                <SelectMenu
                  menuProps={getMenuProps(menuProps)}
                  getItemProps={getItemProps}
                  options={filteredOptions}
                  highlightedIndex={highlightedIndex}
                  selectedItem={selectedItem}
                  valueKey={valueKey!}
                  labelKey={labelKey!}
                  itemToString={this.props.itemToString!}
                  renderItem={this.props.renderOption}
                  optionIcon={optionIcon}
                  target={this.target}
                />
              )}
              {invalid
                ? validationText && (
                    <HelperText persistent validationMsg>
                      {validationText}
                    </HelperText>
                  )
                : helpText && <HelperText persistent={helpTextPersistent}>{helpText}</HelperText>}
            </div>
          );
        }}
      </Downshift>
    );
  }

  itemToString = (i: any) => this.props.itemToString!(i, this.props.labelKey!);

  // itemToString = (item: any) => {
  //   if (typeof item === 'string') return item;
  //   return this.props.itemToString ? this.props.itemToString(item, this.props.labelKey!) : '';
  // };

  handleStateChange = (changes: StateChangeOptions<any>) => {
    let state: Partial<SelectState> = {};

    if (hasOwnProperty.call(changes, 'selectedItem')) {
      const selectedItem = changes.selectedItem;
      state = { ...state, selectedItem };

      const selectedItemType = typeof selectedItem;
      this.props.onSelect?.(selectedItem);
      this.props.onSelect?.(selectedItem);
      this.props.onChange?.(
        this.props.simpleValue && selectedItem && selectedItemType !== 'string' && selectedItemType !== 'number'
          ? selectedItem[this.props.valueKey!]
          : selectedItem,
      );
    }

    if (changes.isOpen === true) {
      if (this.input.current && this.props.searchable) this.input.current.select();
      state = { ...state, filterValue: '' };
    }

    if (hasOwnProperty.call(changes, 'inputValue') && changes.type !== Downshift.stateChangeTypes.clickItem)
      state = { ...state, inputValue: changes.inputValue || '', filterValue: changes.inputValue || '' };
    if (Object.keys(state).length > 0) this.setState(state as SelectState);
  };
}

export default Select;
