import { Ref, cloneElement } from 'react';
import cn from 'clsx';

export function setRef<T>(ref: Ref<T>, value: T) {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    ref.current = value;
  }
}
export type IconElement = React.ReactElement<React.HTMLProps<HTMLOrSVGElement>>;

export function renderIcon(icon: IconElement, className?: string) {
  return cloneElement(icon as any, {
    className: cn(className, icon.props.className),
    'aria-hidden': 'true',
  });
}
