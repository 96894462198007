/* eslint-disable*/
import { ReactInstance } from 'react';
import { findDOMNode } from 'react-dom';

export const getScrollParent = (element: Element) => {
  let style = getComputedStyle(element);
  const excludeStaticParent = style.position === 'absolute';
  const overflowRegex = /(auto|scroll)/;

  if (style.position === 'fixed') return document.body;
  for (let parent: Element | null = element; (parent = parent.parentElement); ) {
    style = getComputedStyle(parent);
    if (excludeStaticParent && style.position === 'static') {
      continue;
    }
    if (overflowRegex.test(style.overflow! + style.overflowY + style.overflowX)) return parent;
  }

  return document.body;
};

let scrollWidth: number | null = null;
export function getScrollWidth() {
  if (scrollWidth === null) {
    const divElement = document.body.appendChild(document.createElement('div'));
    Object.assign(divElement.style, {
      overflow: 'scroll',
      position: 'fixed',
    });
    scrollWidth = divElement.offsetWidth - divElement.clientWidth;
    document.body.removeChild(divElement);
  }
  return scrollWidth;
}

/**
 * Scroll this element into the view
 * @param {HTMLElement} r
 */
export const scrollRefIntoView = (r: ReactInstance) => r && (findDOMNode(r) as HTMLElement).scrollIntoView();

/**
 * Scroll this element into the view horizontally
 * @param {HTMLElement} r
 */
export const scrollRefIntoViewH = (r: ReactInstance) => {
  if (r) {
    const node = findDOMNode(r) as HTMLElement;
    if (node && node.parentElement && node.offsetLeft + node.offsetWidth > node.parentElement.offsetWidth)
      node.parentElement.scrollLeft = node.offsetLeft;
  }
};

export function registerResizeHandler() {
  const onresize = () => document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
  window.addEventListener('resize', onresize, { passive: true, capture: false });
  onresize();
}
