import React from 'react';
import { TextField, TextFieldProps } from '@agroop/ui/lib/TextField/TextField';

import { BaseFieldOptions, useField } from './rcform';
import { getInputProps } from './getInputProps';

type InputProps<T> = BaseFieldOptions<T, Omit<TextFieldProps, 'onChange' | 'defaultValue'>>;

export function Input(o: InputProps<number | string>) {
  const f = useField(o);
  const inputProps = getInputProps(f) as TextFieldProps;
  const error = f.field.error || undefined;
  return <TextField {...inputProps} focused={f.field.focused} label={f.label} invalid={!!error} validationText={error} />;
}
