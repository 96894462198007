import React from 'react';

import { IcWidgets } from '@agroop/icons';
import { SidebarStackItem } from '../services/SidebarService';

import styles from './SidebarExpandToggle.module.scss';

export function SidebarExpandToggle({ sidebar }: { sidebar: SidebarStackItem }) {
  return (
    <div className={styles.set} onClick={sidebar.toggleExpanded} aria-expanded={sidebar.expanded}>
      <IcWidgets />
    </div>
  );
}
