import React from 'react';

import UISelect, { SelectProps } from '@agroop/ui/lib/Select/Select';

import { BaseFieldOptions, useField } from './rcform';

type SelectFieldProps<T> = BaseFieldOptions<T, Omit<SelectProps, 'onChange' | 'defaultValue'>>;

export function Select<T extends any>(o: SelectFieldProps<T>) {
  const { field, form, label, value, props, setValue } = useField(o);
  return (
    <UISelect
      name={field.name}
      onFocus={form.handleInputFocus}
      onBlur={form.handleInputBlur}
      value={value}
      focused={field.focused}
      label={label}
      onChange={setValue}
      required={field.required}
      invalid={!!field.error}
      validationText={field.error || undefined}
      {...props}
    />
  );
}
