import React from 'react';
import cn from 'clsx';
import { ShowOf, ShowOfComponentProps } from 'react-showof';
import { FocusTrap, createFocusTrap } from 'focus-trap';
import './Dialog.scss';
import { Button, ButtonProps } from '../Button/Button';
import { BaseDialog, DialogContent, DialogTitle } from './BaseDialog';

type ButtonType = Omit<ButtonProps & React.HTMLProps<HTMLButtonElement>, 'ref'>;

interface DialogProps {
  children?: React.ReactNode;
  id?: string;
  className?: string;
  open?: boolean;
  animating?: boolean;
  scrollable?: boolean;
  stacked?: boolean;

  title?: string;
  buttons: ButtonType[];

  closeable?: boolean;
  onClose?: () => void;
  onButtonClick?: (name: string, e: React.MouseEvent) => void;
}

export default class Dialog extends React.Component<DialogProps> {
  static defaultProps = {
    buttons: [{ text: 'Ok', name: 'ok' }],
    closeable: true,
  };

  ft: FocusTrap | undefined;

  private setRef: React.Ref<HTMLDivElement> = r => {
    if (r) {
      this.ft = createFocusTrap(r, {
        escapeDeactivates: this.props.closeable,
        clickOutsideDeactivates: this.props.closeable,
        onDeactivate: this.deactivate,
      });
      this.ft.activate();
    } else if (this.ft) this.ft.deactivate();
  };

  private deactivate = () => this.props.onClose?.();

  render() {
    return <ShowOf when={!!this.props.open} render={this._render} />;
  }

  private _render = (props: ShowOfComponentProps<{}>) => (
    <BaseDialog {...props} id={this.props.id} scrollable={this.props.scrollable} stacked={this.props.stacked} ref={this.setRef}>
      {this.props.title && <DialogTitle title={this.props.title} />}
      <DialogContent>{this.props.children}</DialogContent>
      <footer className="mdc-dialog__actions">{this.props.buttons.map(this.renderButton)}</footer>
    </BaseDialog>
  );

  private renderButton = (props: ButtonType) => (
    <Button
      key={props.name}
      {...props}
      className={cn('mdc-dialog__button', props.className)}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        if (props.onClick) props.onClick(e);
        if (this.props.onButtonClick) this.props.onButtonClick(e.currentTarget.name, e);
      }}
    />
  );
}
