/* eslint-disable no-nested-ternary, no-param-reassign, no-plusplus, no-use-before-define */
export type TPrim = string | number;
export type TProp = TPrim | TPrim[];

function toPath(path: TProp): TPrim[] {
  return Array.isArray(path) ? path : typeof path === 'string' ? path.split('.') : [path];
}

/**
 * Get a value by a dot path.
 * @param obj The object to evaluate.
 * @param prop The path to value that should be returned.
 * @param value The default value to return if no result
 */
export function immGet<V = any>(obj: any, prop: TProp, value?: V): V | undefined {
  prop = toPath(prop);

  for (let i = 0; i < prop.length; i++) {
    if (typeof obj !== 'object' || obj === null) return value;
    obj = obj[prop[i]];
  }

  return typeof obj === 'undefined' ? value : (obj as any);
}

/**
 * Set a value by a dot path.
 * @param obj The object to evaluate.
 * @param prop The path to be set.
 * @param value The value to set.
 */
export function immSet<T = any, V = any>(obj: T, prop: TProp, value: ((v: V) => V) | V): T {
  return setPropImmutableRec(obj, toPath(prop), value, 0);
}

function setPropImmutableRec<T extends any>(obj: T, prop: TPrim[], value: any, i: number) {
  const head = prop[i];
  if (prop.length > i) {
    const clone = Array.isArray(obj) ? obj.slice() : Object.assign({}, obj);
    // @ts-ignore
    clone[head] = setPropImmutableRec(obj[head] !== undefined ? obj[head] : {}, prop, value, i + 1);
    return clone;
  }

  return typeof value === 'function' ? value(obj) : value;
}

/**
 * Set many values defined in a map on the obj
 * @param obj
 * @param map
 */
export function immSetAll<T = any>(obj: T, map: Record<TPrim, any>) {
  return Object.keys(map).reduce((acc, path) => immSet(acc, path, map[path]), obj);
}

/**
 * Toggles a value.  The target value is evaluated using Boolean(currentValue).  The result will always be a JSON boolean.
 * Be careful with strings as target value, as "true" and "false" will toggle to false, but "0" will toggle to true.
 * Here is what Javascript considers false:  0, -0, null, false, NaN, undefined, and the empty string ("")
 * @param obj The object to evaluate.
 * @param prop The path to the value.
 */
export function immToggle<T = any>(obj: T, prop: TProp): T {
  return immSet(obj, prop, !immGet(obj, prop));
}

/**
 * Delete a property by a dot path.
 * If target container is an object, the property is deleted.
 * If target container is an array, the index is deleted.
 * If target container is undefined, nothing is deleted.
 * @param obj The object to evaluate.
 * @param prop The path to the property or index that should be deleted.
 */
export function immDelete<T = any>(obj: T, prop: TProp): T {
  // @ts-ignore
  return removePropImmutableRec(obj, toPath(prop), 0);
}

function removePropImmutableRec<T extends any>(obj: T, prop: TPrim[], i: number) {
  let clone;
  const head = prop[i];

  // @ts-ignore
  if (typeof obj !== 'object' || (!Array.isArray(obj) && obj[head] === undefined)) return obj;

  if (prop.length - 1 > i) {
    clone = Array.isArray(obj) ? obj.slice() : Object.assign({}, obj);
    // @ts-ignore
    clone[head] = removePropImmutableRec(obj[head], prop, i + 1);
    return clone;
  }

  if (Array.isArray(obj)) {
    // @ts-ignore
    clone = [].concat(obj.slice(0, head), obj.slice((head as number) + 1));
  } else {
    clone = Object.assign({}, obj);
    // @ts-ignore
    delete clone[head];
  }

  return clone;
}

/**
 * Merges a value.  The target value must be an object, array, null, or undefined.
 * If target is an object, Object.assign({}, target, param) is used.
 * If target an array, target.concat(param) is used.
 * If target is null or undefined, the value is simply set.
 * @param obj The object to evaluate.
 * @param prop The path to the value.
 * @param val The value to merge into the target value.
 */
export function immMerge<T = any>(obj: T, prop: TProp, val: any): T {
  const curVal = immGet(obj, prop);
  if (typeof curVal === 'object') {
    if (Array.isArray(curVal)) {
      return immSet(obj, prop, curVal.concat(val));
    }
    if (curVal === null) {
      return immSet(obj, prop, val);
    }
    const merged = Object.assign({}, curVal, val);
    return immSet(obj, prop, merged);
  }
  if (typeof curVal === 'undefined') {
    return immSet(obj, prop, val);
  }
  return obj;
}
