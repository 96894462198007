import React from 'react';

const addBr = (item: string, key: any, a: string[]) =>
  key < a.length - 1 ? (
    <React.Fragment key={key}>
      {item}
      <br />
    </React.Fragment>
  ) : (
    item
  );

export const breakParagraph = (item: string, key: any) => <p key={key}>{item}</p>;

export function breakTextNode(
  node: React.ReactNode,
  breaker: (item: string, key: any, a: string[]) => React.ReactNode = addBr,
): React.ReactNode {
  if (Array.isArray(node)) return node.map(s => breakTextNode(s));
  // noinspection SuspiciousTypeOfGuard
  return typeof node === 'string' ? node.split('\n').map(breaker) : node;
}
