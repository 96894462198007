import React from 'react';
import { createPopper, Instance, Options } from '@popperjs/core/lib/popper-lite';
import flip from '@popperjs/core/lib/modifiers/flip';
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow';

export type MenuOptions = Partial<Options>;

export interface MenuSurfaceManager {
  ref(r: HTMLDivElement | null): void;
  handleEvent(e: React.SyntheticEvent<HTMLElement>): void;
  readonly show: boolean;
}

export function createMenuSurfaceManager(
  update: () => void,
  isCloseableOnClick: boolean = true,
  options: MenuOptions = {},
): MenuSurfaceManager {
  let show = false;
  let reference: HTMLElement | null = null;
  let popperElement: HTMLElement | null = null;
  let popper: Instance | null = null;

  function hideMenu({ target }: MouseEvent) {
    if (popperElement?.contains(target as Node) && !isCloseableOnClick) return;
    if (reference?.contains(target as Node) && !popperElement?.contains(target as Node)) return;

    show = false;
    update();
  }

  return {
    handleEvent(e: React.SyntheticEvent<HTMLElement>) {
      reference = e.currentTarget;
      if (e.type === 'click') {
        show = !show;
        update();
      }
    },
    ref(r: HTMLDivElement | null) {
      if (r && reference) {
        popper = createPopper(reference, r, {
          strategy: 'fixed',
          modifiers: [flip, { ...preventOverflow, options: { padding: 5 } }],
          ...options,
        });
        document.addEventListener('click', hideMenu);
      } else if (popper) {
        popper.destroy();
        document.removeEventListener('click', hideMenu);
      }
      popperElement = r;
    },
    get show() {
      return show;
    },
  };
}
