import React from 'react';
import cn from 'clsx';

interface HelperTextProps {
  children?: React.ReactNode;
  persistent?: boolean;
  validationMsg?: boolean;
}

export const HelperText: React.FC<HelperTextProps> = ({ children, persistent, validationMsg }) => (
  <p
    className={cn('mdc-text-field-helper-text', {
      'mdc-text-field-helper-text--persistent': persistent,
      'mdc-text-field-helper-text--validation-msg': validationMsg,
    })}
    aria-hidden="true"
  >
    {children}
  </p>
);
