import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { MDCRipple } from '@material/ripple';

export { MDCRipple } from '@material/ripple';
/* eslint-disable react/no-find-dom-node,import/prefer-default-export */
export function assignRipple<T extends React.ReactInstance>(r: T | null): MDCRipple | undefined {
  const node = r && ReactDOM.findDOMNode(r);
  // @ts-ignore
  return node && MDCRipple.attachTo(node);
}

export function attachRipple<T extends Element>(instance: any, r: T | null, options?: { isUnbounded?: boolean }) {
  // eslint-disable-next-line no-param-reassign
  if (r) instance.$mdcRipple = MDCRipple.attachTo(r, options);
  else if (instance.$mdcRipple) (instance.$mdcRipple as MDCRipple).deactivate();
  return r;
}

export function useRipple<T extends HTMLElement>() {
  const ripRef = useRef<T>(null);

  useEffect(() => {
    const rip = MDCRipple.attachTo(ripRef.current!);
    return () => rip.destroy();
  }, []);

  return ripRef;
}
