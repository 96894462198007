/* eslint-disable no-param-reassign, no-use-before-define,react/no-access-state-in-setstate */
import { Client, StompSubscription } from '@stomp/stompjs';
import { Service, ServiceInit } from 'rc-service';
import { UserAuthService } from './Auth';

interface STOMPState {
  wsState: 'loading' | 'ready' | 'wsError';
  client: Client | null;
}

export const stompStatusSelector = (state: STOMPState) => state.wsState;

export class STOMPService extends Service<STOMPState, UserAuthService> {
  static serviceName = 'STOMP';
  private readonly userAuth: UserAuthService;

  constructor(init: ServiceInit, options: UserAuthService) {
    super(init, options);
    this.state = {
      wsState: 'loading',
      client: null,
    };

    this.userAuth = options;
  }

  /**
   * Connects to websocket and returns a promise that is resolved after connect
   */
  private _cp?: Promise<Client> = undefined;

  connect() {
    return (this._cp ??= new Promise(resolve => {
      this.setState({ wsState: 'loading', client: null });
      let token: string;
      const client: Client = new Client({
        forceBinaryWSFrames: true,
        appendMissingNULLonIncoming: true,
        onStompError: this.reject,
        onWebSocketError: this.reject,
        beforeConnect: async () => {
          token = await this.userAuth.verifyToken();
        },
        onConnect: () => {
          this.setState({ wsState: 'ready', client });
          resolve(client);
          this._cp = undefined;
        },
        webSocketFactory: () => {
          try {
            return new WebSocket(`${import.meta.env.VITE_WEBSOCKET_URL}?token=${token}`, client.stompVersions.protocolVersions());
          } catch (e) {
            this.reject(e);
            return {};
          }
        },
        // debug: str => console.log('ws', str),
      });
      client.activate();
    }));
  }

  reject = (_: any) => {
    this.setState({ wsState: 'wsError', client: null });
    this._cp = undefined;
  };

  /** Check if we already have a client and return it, or connect to the server */
  verify = () => (this.state.client ? Promise.resolve(this.state.client) : this.connect());

  async sendMessage<T extends any>(destination: string, body?: T) {
    (await this.verify())?.publish({ destination, body: body && JSON.stringify(body) });
  }

  /**
   * subscribes to a topic, but first it tries to connect to stomp
   * It returns a cleanup function that can be called to unsubscribe the subscription
   * If the cleanup is called before we actually connect to stomp it will not subscribe when connected
   */
  subscribeTo<T>(topicName: string, callback: (data: T) => void): () => void {
    let subscription: StompSubscription | undefined;
    let canceled = false;

    this.verify().then(client => {
      if (!canceled) {
        subscription = client.subscribe(`/topic/${topicName}`, message => callback(JSON.parse(message.body)));
      }
    });

    return () => {
      if (subscription) subscription.unsubscribe();
      else canceled = true;
    };
  }
}
