import React from 'react';
import { Button } from '../Button';
import './error.scss';
import img from './error.svg';

interface NotFoundProps {
  title: string;
  message: string;
  button: string;
  onButtonClick?: React.MouseEventHandler;
}

export const NotFound: React.FC<NotFoundProps> = ({ title, message, button, onButtonClick }) => (
  <div className="agError">
    <div className="agError__text">
      <strong>{title}</strong>
      <div>{message}</div>
    </div>
    <img src={img} className="agError__image" alt="404" />
    <Button className="agError__button" raised onClick={onButtonClick} text={button} />
  </div>
);

export default NotFound;
