import React from 'react';

import cn from 'clsx';

import { LineRipple } from '../mdc/LineRipple';
import { FloatingLabel } from '../mdc/FloatingLabel';
import { HelperText } from './components/HelperText';
import { Icon } from './components/Icon';
import uuid from '../utils/uuid';
import { MdcTextField, MdcTextFieldProps } from './components/MdcTextField';
import './mdc-text-field.scss';
import { EllipsisScrollerInput } from '../EllipsisScroller/EllipsisScroller';

type FormElement = HTMLInputElement | HTMLTextAreaElement;

export interface TextFieldProps extends Omit<MdcTextFieldProps, 'leadingIcon' | 'trailingIcon' | 'onChange' | 'onFocus' | 'onBlur'> {
  label?: string;
  id?: string;
  name?: string;
  value?: string | number;
  type?: string;
  open?: boolean;
  className?: string;
  required?: boolean;
  helpText?: string;
  validationText?: string;
  helpTextPersistent?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
  leadingIcon?: React.ReactElement<SVGElement>;
  trailingIcon?: React.ReactElement<SVGElement>;
  onChange?: React.ChangeEventHandler<FormElement>;
  onBlur?: React.FocusEventHandler<FormElement>;
  onFocus?: React.FocusEventHandler<FormElement>;
  autoFocus?: boolean;
  autoComplete?:
    | 'off'
    | 'off'
    | 'on'
    | 'name'
    | 'name'
    | 'honorific-prefix'
    | 'given-name'
    | 'additional-name'
    | 'family-name'
    | 'honorific-suffix'
    | 'nickname'
    | 'email'
    | 'username'
    | 'new-password'
    | 'current-password'
    | 'organization-title'
    | 'organization'
    | 'street-address'
    | 'address-line1'
    | 'address-line2'
    | 'address-line3'
    | 'street-address'
    | 'address-level4'
    | 'address-level3'
    | 'address-level2'
    | 'address-level1'
    | 'country'
    | 'country-name'
    | 'postal-code'
    | 'cc-name'
    | 'cc-given-name'
    | 'cc-additional-name'
    | 'cc-family-name'
    | 'cc-number'
    | 'cc-exp'
    | 'cc-exp-month'
    | 'cc-exp-year'
    | 'cc-csc'
    | 'cc-type'
    | 'transaction-currency'
    | 'transaction-amount'
    | 'transaction-currency'
    | 'language'
    | 'bday'
    | 'bday-day'
    | 'bday-month'
    | 'bday-year'
    | 'sex'
    | 'tel'
    | 'tel-country-code'
    | 'tel-national'
    | 'tel-area-code'
    | 'tel-local'
    | 'tel-local-prefix'
    | 'tel-local-suffix'
    | 'tel-extension'
    | 'impp'
    | 'url'
    | 'photo'
    | 'address-level1'
    | 'address-level4'
    | 'address-level1'
    | 'address-level1'
    | 'address-level2';
  readOnly?: boolean;
  noValidate?: boolean;
  step?: string | number;
  min?: string | number;
  max?: string | number;
}

export const mdcTextFieldWrapper = 'mdc-text-field__wrapper';
export const mdcTextFieldInput = 'mdc-text-field__input';

export class TextField extends React.Component<TextFieldProps> {
  id = this.props.id || uuid();
  render() {
    const {
      label,
      name,
      type = 'text',
      className,
      disabled,
      required,
      box,
      inline,
      textArea,
      helpText,
      validationText,
      helpTextPersistent,
      leadingIcon,
      trailingIcon,
      invalid,
      focused,
      value,
      inputRef,
      // eslint-disable-next-line eqeqeq
      open = focused || value == '0' || !!value,
      ...other
    } = this.props;
    return (
      <div className={cn(mdcTextFieldWrapper, className)}>
        <MdcTextField
          disabled={disabled}
          focused={focused}
          invalid={invalid}
          box={box}
          inline={inline}
          textArea={textArea}
          leadingIcon={!!leadingIcon}
          trailingIcon={!!trailingIcon}
        >
          {leadingIcon && <Icon icon={leadingIcon} pos="leading" />}
          <EllipsisScrollerInput>
            {React.createElement(textArea ? 'textarea' : 'input', {
              type,
              name,
              id: this.id,
              className: mdcTextFieldInput,
              disabled,
              value,
              required,
              ref: inputRef,
              ...other,
            })}
          </EllipsisScrollerInput>
          {label && (
            <FloatingLabel active={open} htmlFor={this.id} required={required}>
              {label}
            </FloatingLabel>
          )}
          {trailingIcon && <Icon icon={trailingIcon} pos="trailing" />}
          {!textArea && <LineRipple active={focused!} />}
        </MdcTextField>
        {invalid
          ? validationText && (
              <HelperText persistent validationMsg>
                {validationText}
              </HelperText>
            )
          : helpText && <HelperText persistent={helpTextPersistent}>{helpText}</HelperText>}
      </div>
    );
  }
}
