import React from 'react';
import cn from 'clsx';

export interface MdcTextFieldProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  focused?: boolean;
  invalid?: boolean;
  box?: boolean;
  inline?: boolean;
  textArea?: boolean;
  leadingIcon?: boolean;
  trailingIcon?: boolean;
}

export const MdcTextField = React.forwardRef<HTMLDivElement, MdcTextFieldProps>(
  ({ disabled, invalid, inline, box, textArea, focused, leadingIcon, trailingIcon, className, children, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'mdc-text-field mdc-text-field--filled',
        {
          'mdc-text-field--disabled': disabled,
          'mdc-text-field--invalid': invalid,
          'mdc-text-field--block': !inline,
          'mdc-text-field--no-box': !box && !textArea,
          'mdc-text-field--textarea': textArea,
          'mdc-text-field--focused': focused,
          'mdc-text-field--with-leading-icon': leadingIcon,
          'mdc-text-field--with-trailing-icon': trailingIcon,
        },
        className,
      )}
      {...props}
    >
      {children}
    </div>
  ),
);
