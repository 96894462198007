export const mdcTypographyHeadline4 = 'mdc-typography--headline4';
export const mdcTypographyHeadline5 = 'mdc-typography--headline5';
export const mdcTypographyHeadline6 = 'mdc-typography--headline6';
export const mdcTypographySubtitle1 = 'mdc-typography--subtitle1';
export const mdcTypographyBody2 = 'mdc-typography--body2';
export const mdcTypographyButton = 'mdc-typography--button';

// new styles

export const typographyBodySmall = 'typography--bodySmall';
export const typographyBodyMedium = 'typography--bodyMedium';
export const typographyBodyLarge = 'typography--bodyLarge';

export const typographyHeadlineSmall = 'typography--headlineSmall';

export const typographyLabelSmall = 'typography--labelSmall';
export const typographyLabelLarge = 'typography--labelLarge';

export const typographyTitleSmall = 'typography--titleSmall';
export const typographyTitleMedium = 'typography--titleMedium';
