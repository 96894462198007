import { useEffect, useRef, useContext } from 'react';

import { useService } from 'rc-service';
import { CloseHandler, SidebarContext, SidebarOptions, SidebarService, SidebarStackItem } from '../services/SidebarService';

export function useSidebar(options?: SidebarOptions | CloseHandler): SidebarStackItem {
  const sidebarService = useService(SidebarService);
  const sidebarRef = useRef<SidebarStackItem>();
  const opt = options instanceof Function ? { onClose: options } : options || {};

  // create a new sidebar on first render and keep it
  const sidebar = sidebarRef.current || (sidebarRef.current = sidebarService.openSidebar(opt));
  // Hot wire the close handler again
  sidebar.options = opt;
  sidebar.closeable = opt.closeable !== undefined ? opt.closeable : !!opt.onClose || !!opt.onBeforeClose;
  // remove sidebar after unmount
  useEffect(() => () => sidebarService.popStack(sidebar), []);
  return sidebarService.state.sidebars[sidebar.id];
}

export const useSidebarCtx = () => useContext(SidebarContext);
