import React from 'react';
import cn from 'clsx';

interface IconProps {
  icon: React.ReactElement<any>;
  pos: 'trailing' | 'leading';
}

export const Icon: React.FC<IconProps> = ({ icon, pos }) =>
  React.cloneElement(icon, {
    className: cn(`mdc-text-field__icon mdc-text-field__icon--${pos}`, icon.props.className),
  });
