/**
 * Auto-generated, do not edit, it will be overwritten
 * https://github.com/porfirioribeiro/openapi2ts
 */
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { request, ResponsePromise } from '..';
import * as types from './types';

export const getConductionSystemByLocale = (): ResponsePromise<types.IdNameResponse[], any> =>
  request({
    module: 'commons',
    method: 'GET',
    uri: '/conSys',
  });

export const getConductionSystemByLocaleKey = 'getConductionSystemByLocale';

export const useGetConductionSystemByLocale = <TData = types.IdNameResponse[]>(
  queryConfig?: Partial<UseQueryOptions<types.IdNameResponse[], unknown, TData>>,
) => useQuery({ queryFn: async () => getConductionSystemByLocale(), queryKey: [getConductionSystemByLocaleKey], ...queryConfig });
