import { useState, useMemo } from 'react';
import { createPopper, Instance, Options } from '@popperjs/core/lib/popper-lite';
import flip from '@popperjs/core/lib/modifiers/flip';
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow';

export type TooltipOptions = Partial<Options>;

const events = ['click', 'mouseenter', 'mouseleave', 'touchstart', 'touchend', 'focus', 'blur'];

export function createNativeTooltipManager(setShowTip: (on: boolean) => void, options?: TooltipOptions) {
  let referenceEl: Element | null = null;
  let popper: Instance | null = null;
  let isTouchdown = false;
  let hideTimer = 0;
  let showTimer = 0;

  function handleEvent(e: TouchEvent | MouseEvent | FocusEvent) {
    if (e.type === 'touchstart') {
      clearTimeout(hideTimer);
      isTouchdown = true;
      showTimer = window.setTimeout(() => {
        setShowTip(true);
        hideTimer = window.setTimeout(setShowTip, 1500, false);
      }, 200);
    } else if (e.type === 'touchend') {
      clearTimeout(showTimer);
      setTimeout(() => (isTouchdown = false));
    } else if (!isTouchdown) setShowTip(e.type === 'mouseenter' || e.type === 'focus' || e.type === 'click');
  }

  function reference(r: Element | null) {
    if (r) events.forEach(type => r.addEventListener(type, handleEvent));
    else if (referenceEl) events.forEach(type => referenceEl!.removeEventListener(type, handleEvent));
    referenceEl = r;
  }
  function target(r: HTMLDivElement | null) {
    if (r && referenceEl)
      popper = createPopper(referenceEl, r, {
        placement: 'auto',
        strategy: 'fixed',
        modifiers: [flip, preventOverflow],
        ...options,
      });
    else if (popper) popper.destroy();
  }
  return { reference, target };
}
type SetRef = (r: Element | null) => void;

export function useSimpleTooltip(options?: TooltipOptions): { showTip: boolean; reference: SetRef; target: SetRef } {
  const [showTip, setShowTip] = useState(false);
  const { reference, target } = useMemo(() => createNativeTooltipManager(setShowTip, options), []);
  return { showTip, reference, target };
}
