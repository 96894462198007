/**
 * Auto-generated, do not edit, it will be overwritten
 * https://github.com/porfirioribeiro/openapi2ts
 */
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { request, ResponsePromise } from '..';
import * as types from './types';

export interface GetAllPestsOptions {
  cropId?: string;
  growthStageId?: string;
}

export const getAllPests = (options: GetAllPestsOptions): ResponsePromise<types.PlagueWithCropResponse[], GetAllPestsOptions> =>
  request({
    module: 'commons',
    method: 'GET',
    uri: '/pest',
    query: { cropId: options.cropId, growthStageId: options.growthStageId },
    options,
  });

export const getAllPestsKey = 'getAllPests';

export const useGetAllPests = <TData = types.PlagueWithCropResponse[]>(
  options: GetAllPestsOptions,
  queryConfig?: Partial<UseQueryOptions<types.PlagueWithCropResponse[], unknown, TData>>,
) =>
  useQuery({
    queryFn: async () => getAllPests(options),
    queryKey: [getAllPestsKey, options.cropId, options.growthStageId],
    ...queryConfig,
  });
