import React from 'react';
import cn from 'clsx';
import { IcMoreVert } from '@agroop/icons';
import { IconElement } from '@agroop/ui/lib/utils/reactUtils';
import IconButton from '@agroop/ui/lib/IconButton';
import { List, ListDivider, SimpleListItem } from '@agroop/ui/lib/ItemList';
import { Button } from '@agroop/ui/lib/Button';
import styles from './Action.module.scss';
import { BadgeComponent } from './Switcher';

export type ActionShowAs = 'button' | 'icon' | 'menu';

type ActionShape = {
  showAs?: ActionShowAs | 'separator';
  icon?: IconElement;
  badge?: BadgeComponent;
  title?: string;
  className?: string;
  onClick?: React.MouseEventHandler;
  menuView?: React.ComponentClass | React.FunctionComponent | React.ReactNode;
};

export function Action(_: ActionShape) {
  return null;
}

function renderButton(action: ActionShape, textButton: boolean, key: any) {
  return textButton ? (
    <Button className={action.className} key={key} text={action.title} icon={action.icon} onClick={action.onClick} menu={action.menuView} />
  ) : (
    <IconButton
      key={key}
      icon={action.icon!}
      className={action.className}
      tooltip={action.title}
      tooltipOptions={{ placement: 'right' }}
      onClick={action.onClick}
      menu={action.menuView}
    >
      {action.badge}
    </IconButton>
  );
}

function renderMenuItem(action: ActionShape, key: any) {
  return action.showAs === 'separator' ? (
    <ListDivider key={key} />
  ) : (
    <SimpleListItem key={key} className={action.className} text={action.title} graphic={action.icon} onClick={action.onClick} />
  );
}

interface ActionBarProps {
  maxActions?: number;
  className?: string;
  defaultShowAs?: ActionShowAs;
  icon?: IconElement;
}

type ActionElement = React.ReactElement<ActionShape>;

export type ActionChildren = React.ReactElement | undefined | null | false | ActionChildren[];

export function getChildActions(children: ActionChildren, arr: ActionElement[] = []): ActionElement[] {
  if (children) {
    if (Array.isArray(children)) children.map(child => getChildActions(child, arr));
    else if (children.type === React.Fragment) getChildActions(children.props.children, arr);
    else if (children.type === Action) arr.push(children as ActionElement);
    else if (process.env.NODE_ENV !== 'production') throw new Error('Invalid children passed to ActionView, should be of type Action');
  }
  return arr;
}

function mapIt(children: ActionChildren, maxActions: number, defaultShowAs: ActionShowAs) {
  const actions: React.ReactElement[] = [];
  const menu: React.ReactElement[] = [];
  getChildActions(children).forEach((child: ActionElement, index, all) => {
    const action = child.props;
    const { showAs = defaultShowAs } = action;
    const isBtn = showAs === 'icon' || showAs === 'button';
    if (isBtn && (all.length === 1 || maxActions - actions.length !== 0)) {
      actions.push(renderButton(action, showAs === 'button', child.key || index));
    } else {
      menu.push(renderMenuItem(action, child.key || index));
    }
  });
  return [actions, menu];
}

export function ActionBar({
  className,
  maxActions = -1,
  defaultShowAs = 'icon',
  icon = <IcMoreVert />,
  children,
  ...props
}: ActionBarProps & { children?: ActionChildren; onClick?: React.MouseEventHandler }) {
  if (!children) return null;
  const [actions, menu] = mapIt(children, maxActions, defaultShowAs);
  return (
    <div className={cn(styles.root, className)} {...props}>
      {actions}
      {menu.length > 0 && <IconButton icon={icon} menu={() => <List>{menu}</List>} />}
    </div>
  );
}
