// color
export const mdcThemeSecondary = 'mdc-theme--secondary';
export const mdcThemeTextSecondaryOnBackground = 'mdc-theme--text-secondary-on-background';
export const mdcThemeTextPrimaryOnLight = 'mdc-theme--text-primary-on-light';
export const mdcThemeTextSecondaryOnLight = 'mdc-theme--text-secondary-on-light';
export const mdcThemeTextDisabledOnLight = 'mdc-theme--text-disabled-on-light';

export const themeOnPrimary = 'theme-on-primary';
export const themePrimary = 'theme-primary--300'; // Main primary
export const themePrimary400 = 'theme-primary--400';
export const themePrimary700 = 'theme-primary--700';
export const themePrimary900 = 'theme-primary--900';

export const themePrimaryBackoffice = 'theme-primary--backoffice';

export const themeBlue400 = 'theme-blue--400';

export const themeTextSecondary = 'theme-neutral--500';
export const themeNeutral600 = 'theme-neutral--600';
export const themeTextPrimary = 'theme-neutral--800';

export const themeError500 = 'theme-error--500';
export const themeError700 = 'theme-error--700';

export const themeSecondary = 'theme-secondary--500';

export const themeWarning900 = 'theme-warning--900';

// background

export const themeBg = 'theme-neutral--100-bg';
export const themeBgSecondary = 'theme-neutral--200-bg';
export const themePrimaryBg = 'theme-primary--300-bg';
export const mdcThemePrimaryBg = 'mdc-theme--primary-bg';
export const themeSecondaryBg = 'theme-secondary--500-bg';
export const themePrimary700Bg = 'theme-primary--700-bg';
export const themeTextPrimaryBg = 'theme-neutral--800-bg';
export const themeTextPrimaryOnBg = 'theme-text-primary--on-bg';
