import React from 'react';

import cn from 'clsx';

import './mdc-grid.scss';
import { wrapChildren, WrapOption } from '../utils/wrap';
import { typographyTitleMedium } from '../mdc';

export type Columns = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface GridProps {
  children?: React.ReactNode;
  alignment?: 'left' | 'right';
  fixedColumnWidth?: boolean;
  style?: React.CSSProperties;
  fullWidth?: boolean;
  className?: string;
}

export const Grid: React.FC<GridProps> = ({ children, className, alignment, fixedColumnWidth, fullWidth = true, ...props }) => (
  <div
    className={cn(
      'mdc-layout-grid',
      {
        [`mdc-layout-grid--align-${alignment}`]: alignment,
        'mdc-layout-grid--fixed-column-width': fixedColumnWidth,
        'mdc-layout-grid--full-width': fullWidth,
      },
      className,
    )}
  >
    <GridInner {...props}>{children}</GridInner>
  </div>
);

interface GridInnerProps extends React.HTMLProps<HTMLDivElement> {}
export const GridInner: React.FC<GridInnerProps> = ({ children, className, ...props }) => (
  <div className={cn('mdc-layout-grid__inner', className)} {...props}>
    {children}
  </div>
);

interface GridCellProps {
  children?: React.ReactNode;
  className?: string;
  wrap?: WrapOption;
  span?: Columns;
  desktop?: string | number;
  phone?: string | number;
  tablet?: string | number;
  order?: string | number;
  align?: 'top' | 'middle' | 'bottom';
}

const mdcLayoutGridCell = 'mdc-layout-grid__cell';

export const GridCell = React.forwardRef<HTMLDivElement, GridCellProps>(
  ({ children, className, wrap, span, desktop, phone, tablet, order, align }, ref) =>
    !children
      ? null
      : wrapChildren(children, wrap, 'div', {
          ref,
          className: cn(
            mdcLayoutGridCell,
            {
              [`${mdcLayoutGridCell}--span-${span}`]: span,
              [`${mdcLayoutGridCell}--span-${desktop}-desktop`]: desktop,
              [`${mdcLayoutGridCell}--span-${phone}-phone`]: phone,
              [`${mdcLayoutGridCell}--span-${tablet}-tablet`]: tablet,
              [`${mdcLayoutGridCell}--order-${order}`]: order,
              [`${mdcLayoutGridCell}--align-${align}`]: align,
            },
            className,
            (children as React.ReactElement<{ className?: string }>)?.props?.className,
          ),
        }),
);

export const GridHeader: React.FC<React.HTMLProps<HTMLHeadingElement>> = props => (
  // eslint-disable-next-line jsx-a11y/heading-has-content
  <h6 {...props} className={`${mdcLayoutGridCell} ${mdcLayoutGridCell}--span-12 ${typographyTitleMedium} ${props.className || ''}`} />
);
