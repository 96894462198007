/* eslint-disable no-plusplus */
import { arraysEqual, arrayWithout } from './array';

const hasOwnProperty = Object.prototype.hasOwnProperty;

/**
 * Performs equality by iterating through keys on an object and returning false
 * when any key has values which are not strictly equal between the arguments.
 * It ignores if the prop is a function or if it's name is ignored
 * Returns true when the values of all keys are strictly equal.
 */
export default function shallowEqual(objA: any, objB: any, ignore?: string | string[]): boolean {
  if (Object.is(objA, objB)) return true;

  if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
    return false;
  }

  let keysA = Object.keys(objA);
  let keysB = Object.keys(objB);

  if (ignore) {
    keysA = arrayWithout(keysA, ignore);
    keysB = arrayWithout(keysA, ignore);
  }

  if (keysA.length !== keysB.length) return false;

  // Test for A's keys different from B.
  for (let i = 0; i < keysA.length; i++) {
    const valueA = objA[keysA[i]];
    const valueB = objB[keysB[i]];

    if (typeof valueA === 'object' && typeof valueB === 'object' && !Array.isArray(valueA)) return shallowEqual(valueA, valueB);

    if (
      !hasOwnProperty.call(objB, keysA[i]) ||
      (!Object.is(valueA, valueB) && typeof valueA !== 'function' && !Array.isArray(valueA)) ||
      (Array.isArray(valueA) && Array.isArray(valueB) && !arraysEqual(valueA, valueB))
    ) {
      return false;
    }
  }

  return true;
}
