import React from 'react';
import cn from 'clsx';
import '@material/floating-label/mdc-floating-label.scss';

interface FloatingLabelProps extends React.HTMLProps<HTMLLabelElement> {
  active: boolean;
  shake?: boolean;
  required?: boolean;
}
/* eslint-disable jsx-a11y/label-has-for */
/**
 *
 */
export const FloatingLabel: React.FC<FloatingLabelProps> = ({ className, children, active, shake, required, ...props }) => (
  <label
    className={cn(
      'mdc-floating-label',
      {
        'mdc-floating-label--float-above': active,
        'mdc-floating-label--shake': shake,
        'mdc-floating-label--required': required,
      },
      className,
    )}
    {...props}
  >
    {children}
  </label>
);
