/**
 * Auto-generated, do not edit, it will be overwritten
 * https://github.com/porfirioribeiro/openapi2ts
 */
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { request, ResponsePromise } from '..';
import * as types from './types';

export const getCropListByLocale = (): ResponsePromise<types.CropTypeResponse[], any> =>
  request({
    module: 'commons',
    method: 'GET',
    uri: '/crop',
  });

export const getCropListByLocaleKey = 'getCropListByLocale';

export const useGetCropListByLocale = <TData = types.CropTypeResponse[]>(
  queryConfig?: Partial<UseQueryOptions<types.CropTypeResponse[], unknown, TData>>,
) => useQuery({ queryFn: async () => getCropListByLocale(), queryKey: [getCropListByLocaleKey], ...queryConfig });

export const getAllCropsListByLocale = (): ResponsePromise<types.CropTypeResponse[], any> =>
  request({
    module: 'commons',
    method: 'GET',
    uri: '/crop/all',
  });

export const getAllCropsListByLocaleKey = 'getAllCropsListByLocale';

export const useGetAllCropsListByLocale = <TData = types.CropTypeResponse[]>(
  queryConfig?: Partial<UseQueryOptions<types.CropTypeResponse[], unknown, TData>>,
) => useQuery({ queryFn: async () => getAllCropsListByLocale(), queryKey: [getAllCropsListByLocaleKey], ...queryConfig });

export interface FindCropByNameOrAliasOptions {
  name: string;
}

export const findCropByNameOrAlias = (
  options: FindCropByNameOrAliasOptions,
): ResponsePromise<types.CropByNameProjection[], FindCropByNameOrAliasOptions> =>
  request({
    module: 'commons',
    method: 'GET',
    uri: '/crop/name',
    query: { name: options.name },
    options,
  });

export const findCropByNameOrAliasKey = 'findCropByNameOrAlias';

export const useFindCropByNameOrAlias = <TData = types.CropByNameProjection[]>(
  options: FindCropByNameOrAliasOptions,
  queryConfig?: Partial<UseQueryOptions<types.CropByNameProjection[], unknown, TData>>,
) => useQuery({ queryFn: async () => findCropByNameOrAlias(options), queryKey: [findCropByNameOrAliasKey, options.name], ...queryConfig });

export interface GetCropDataByIdOptions {
  id: string;
}

export const getCropDataById = (options: GetCropDataByIdOptions): ResponsePromise<types.CropData, GetCropDataByIdOptions> =>
  request({
    module: 'commons',
    method: 'GET',
    uri: '/crop/{id}',
    path: { id: options.id },
    options,
  });

export const getCropDataByIdKey = 'getCropDataById';

export const useGetCropDataById = <TData = types.CropData>(
  options: GetCropDataByIdOptions,
  queryConfig?: Partial<UseQueryOptions<types.CropData, unknown, TData>>,
) => useQuery({ queryFn: async () => getCropDataById(options), queryKey: [getCropDataByIdKey, options.id], ...queryConfig });
