import React from 'react';
import cn from 'clsx';
import { TopAppBar, TopAppBarRow, TopAppBarSection, TopAppBarTitle } from '@agroop/ui/lib/TopAppBar';
import { ScrollState } from '@agroop/common/utils/ScrollManager';
import { ActionBar, ActionChildren } from './Action';

import styles from './Header.module.scss';
import { ContentSize } from '../services/AppShellService';

export interface HeaderProps {
  title?: React.ReactNode;
  actions?: ActionChildren;
  navigationIcon?: React.ReactNode;
  firstSection?: React.ReactNode;
  endSection?: React.ReactNode;
  children?: React.ReactNode;
  scrollState: ScrollState;
  isSmallUI: boolean;
  fixedBackground?: string;
  fixedColor?: string;
  spacedTabs?: ContentSize;
}

export function Header({
  children,
  navigationIcon,
  actions,
  title,
  firstSection,
  endSection,
  scrollState,
  isSmallUI,
  fixedBackground,
  fixedColor,
  spacedTabs,
}: HeaderProps) {
  const scrolled = children ? scrollState.dir !== 'none' : scrollState.dir === 'back';
  return (
    <TopAppBar
      fixed
      scrolled={scrolled}
      className={cn(styles.headerRoot, scrollState.dir, { [styles.inline]: !isSmallUI })}
      style={fixedBackground && !scrolled ? { background: fixedBackground } : undefined}
    >
      <TopAppBarRow className={styles.toolbar} style={fixedColor && !scrolled ? { color: fixedColor } : undefined}>
        <TopAppBarSection>
          {navigationIcon}
          {title && <TopAppBarTitle>{title}</TopAppBarTitle>}
          {firstSection}
        </TopAppBarSection>
        {(actions || endSection) && (
          <TopAppBarSection align="end">
            {actions && <ActionBar maxActions={isSmallUI ? 0 : 2}>{actions}</ActionBar>}
            {endSection}
          </TopAppBarSection>
        )}
      </TopAppBarRow>
      {children && <TopAppBarRow className={cn(styles.tabBar, spacedTabs)}>{children}</TopAppBarRow>}
    </TopAppBar>
  );
}
