import { immSet } from '@agroop/common/utils/immutable';
import { FormCtx, ValidationFunction } from './types';

function tryParse<T>(v: string | undefined): T | null {
  try {
    return JSON.parse(v!);
  } catch (_) {
    return null;
  }
}

export const getDataSet = <T>(): T =>
  (document.activeElement
    ? Object.fromEntries(Object.entries((document.activeElement as HTMLElement).dataset).map(([k, v]) => [k, tryParse(v)]))
    : {}) as unknown as T;

export const updateField = (state: FormCtx, name: string, value: any, markDirty: boolean): FormCtx => {
  const newState: FormCtx = {
    ...state,
    dirty: markDirty ? true : state.dirty,
    formData: immSet(state.formData, name, value),
  };
  if (markDirty && state.fields[name] && !state.fields[name].dirty) {
    newState.fields = {
      ...state.fields,
      [name]: Object.assign({}, state.fields[name], { dirty: true }),
    };
  }
  return newState;
};

type TransformFunc<T> = (value: T, ...rest: any[]) => T;
type TransformDef = <T>(func: TransformFunc<T> | undefined, value: T, ...rest: any[]) => T;

export const transform: TransformDef = (func, value, ...rest) => (func ? func(value, ...rest) : value);

export const findFirst = (array: ValidationFunction[], fn: (vf: ValidationFunction) => string | false): string | false => {
  for (let i = 0; i < array.length; i += 1) {
    const r = fn(array[i]);
    if (r) return r;
  }
  return false;
};
