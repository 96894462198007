import { HTMLProps, ChangeEventHandler, FocusEventHandler } from 'react';
import { UseFieldResult } from './rcform';

const commaRE = /([,.])/;
const replaceComma = (_m: string, _x: string, offset: number) => (offset ? '.' : '0.');
export type BaseInputFieldProps = { type?: string; pattern?: string; step?: string | number; min?: string | number; max?: string | number };

export function getInputProps<T extends string | number>(
  { field, props, form, value, setValue }: UseFieldResult<T, BaseInputFieldProps>,
  inputProps: HTMLProps<HTMLInputElement> = {},
): HTMLProps<HTMLInputElement> {
  const isNumeric = props.type === 'number';
  const pattern = props.pattern ?? isNumeric ? '-?(\\d*(\\.|,)?\\d*)' : undefined;
  const step = props.step ?? isNumeric ? 'any' : undefined;

  const onChange: ChangeEventHandler<HTMLInputElement> = e => {
    let newValue = e.currentTarget.value;
    if (isNumeric && newValue !== '') {
      // Validate numeric input and replace commas
      if (!e.currentTarget.validity.valid) return;

      newValue = newValue.replace(commaRE, replaceComma);
    }
    setValue(newValue);
  };
  const onBlur: FocusEventHandler<HTMLInputElement> = e => {
    const newValue = e.currentTarget.value;
    if (props.max !== undefined && newValue > props.max) setValue(props.max);
    if (props.min !== undefined && newValue < props.min) setValue(props.min);

    form.handleInputBlur(e);
  };
  return {
    ...inputProps,
    ...props,
    name: field.name,
    type: isNumeric ? 'string' : props.type,
    required: field.required,
    pattern,
    step,
    value: value ?? '',
    onChange,
    onFocus: form.handleInputFocus,
    onBlur: isNumeric ? onBlur : form.handleInputBlur,
  };
}
