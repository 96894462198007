import React from 'react';
import { ShowOfComponentProps, ShowOf } from 'react-showof';
import cn from 'clsx';

import Portal from '../Portal';
import { TooltipOptions, useSimpleTooltip } from './useSimpleTooltip';
import { breakTextNode } from '../utils/break';
import styles from './Tooltip.module.scss';
import { typographyBodySmall } from '../mdc';

type TooltipProps = React.PropsWithChildren<{
  message: React.ReactNode;
  maxWidth?: number;
  img?: string;
  imgAlt?: string;
}>;

export const Tooltip = React.forwardRef<HTMLDivElement, ShowOfComponentProps<TooltipProps>>(
  ({ state, message, maxWidth, img, imgAlt, onTransitionEnd }, ref) => (
    <Portal>
      <div
        ref={ref}
        className={`${cn({ [styles.tooltip]: img }, typographyBodySmall)} mdc-tooltip mdc-tooltip--${state}`}
        style={{ maxWidth: `${maxWidth}px` }}
        onTransitionEnd={onTransitionEnd}
      >
        {img && <img src={img} alt={imgAlt} />}
        {breakTextNode(message)}
      </div>
    </Portal>
  ),
);

interface TipProps extends TooltipOptions {
  children: React.ReactElement;
  message: React.ReactNode;
  maxWidth?: number;
  img?: string;
  imgAlt?: string;
}

export function Tip({ children, message, maxWidth, img, imgAlt, ...options }: TipProps) {
  const { showTip, reference, target } = useSimpleTooltip(options);

  return (
    <>
      {React.cloneElement(children, { ref: reference })}
      <ShowOf render={Tooltip} when={showTip} message={message} img={img} imgAlt={imgAlt} maxWidth={maxWidth} ref={target} />
    </>
  );
}
