import React, { useEffect } from 'react';
import cn from 'clsx';

import { mapSidebarStackItems, SidebarStackItem, SidebarState } from '../services/SidebarService';
import { SidebarExpandToggle } from './SidebarExpandToggle';
import styles from './Sidebar.module.scss';
import { matchSmallUI, useMediaQuery } from '../utils';

const zIndexBase = 7;

interface SidebarManagerProps {
  state: SidebarState;
  closeCurrent(): void;
}

export function SidebarManager({ state, closeCurrent }: SidebarManagerProps) {
  const [isSmallUI] = useMediaQuery(matchSmallUI);
  const sidebarCount = state.stack.length;
  const isSidebarExpandable = state.isSidebarExpandable;

  useEffect(() => {
    document.body.style.marginRight = sidebarCount > 0 ? `${window.innerWidth - document.body.offsetWidth}px` : '0';
    document.body.classList.toggle(styles.noScroll, sidebarCount > 0);
  }, [sidebarCount]);

  return (
    <>
      {mapSidebarStackItems(state).map((sidebar, i) => {
        const open = i < sidebarCount && state.closing !== i;
        const right = !sidebar.expanded && i < sidebarCount - 1 && state.closing !== i + 1 ? `${(sidebarCount - i - 1) * 5}%` : undefined;
        const { scrim = 'blur', animated = true, expandable } = sidebar.options;
        return (
          <React.Fragment key={sidebar.id}>
            <div
              className={cn(styles.scrim, scrim, { animated, [styles.scrimOpen]: open })}
              style={{ zIndex: zIndexBase }}
              onClick={sidebar.closeable ? closeCurrent : undefined}
            />
            <div
              className={cn(styles.sidebar, {
                animated,
                [styles.sidebarOpen]: open,
                [styles.sidebarExpanded]: isSmallUI || (sidebar.expanded && open && isSidebarExpandable),
              })}
              style={{
                zIndex: zIndexBase,
                right,
              }}
            >
              {isSidebarExpandable && expandable && open && <SidebarExpandToggle sidebar={sidebar as SidebarStackItem} />}
              <div className={cn(styles.sidebarInner, sidebar.options.className)} ref={sidebar.ref} style={sidebar.options.style} />
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
}
