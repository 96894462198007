export function getObject<T>(key: string, defaultValue: T): T;
export function getObject<T>(key: string): T | undefined;
export function getObject<T>(key: string, defaultValue?: T): T | undefined {
  let v = defaultValue;
  try {
    const l = localStorage.getItem(key);
    if (l) v = JSON.parse(l);
  } catch (_) {
    /* noop */
  }
  return v;
}
