import React, { useEffect } from 'react';
import cn from 'clsx';
import { ShowOf, ShowOfComponentProps } from 'react-showof';
import { IcClose } from '@agroop/icons';
import { Button } from '../Button';
import IconButton from '../IconButton';
import './Snackbar.scss';

export type SnackbarSeverities = 'info' | 'error';

export type CloseReason = 'dismiss' | 'action';

interface SnackbarImplProps {
  message?: string;
  action?: string;
  stacked?: boolean;
  leading?: boolean;
  onClose(reason: CloseReason): void;
  severity?: SnackbarSeverities;
}

interface SnackbarProps extends SnackbarImplProps {
  open: boolean;
  timeout?: number;
}

function SnackbarImpl({
  state,
  onTransitionEnd,
  message,
  action,
  stacked,
  leading,
  onClose,
  severity = 'info',
}: ShowOfComponentProps<SnackbarImplProps>) {
  return (
    <div
      onTransitionEnd={onTransitionEnd}
      className={cn('mdc-snackbar', {
        'mdc-snackbar--open': state === 'enter',
        'mdc-snackbar--opening': state === 'enter',
        'mdc-snackbar--closing': state === 'exit',
        'mdc-snackbar--stacked': stacked,
        'mdc-snackbar--leading': leading,
      })}
    >
      <div
        className={cn('mdc-snackbar__surface', {
          'mdc-snackbar__error': severity === 'error',
          'mdc-snackbar__info': !severity || severity === 'info',
        })}
      >
        <div className="mdc-snackbar__label" role="status" aria-live="polite">
          {message}
        </div>
        <div className="mdc-snackbar__actions">
          {action && <Button className="mdc-snackbar__action" ripple={false} text={action} onClick={() => onClose('action')} />}
          <IconButton className="mdc-snackbar__dismiss" ripple={false} icon={<IcClose />} onClick={() => onClose('dismiss')} />
        </div>
      </div>
    </div>
  );
}

export function Snackbar({ timeout = 5000, open, ...props }: SnackbarProps) {
  useEffect(() => {
    if (!open || !timeout) return undefined;
    const t = setTimeout(props.onClose, timeout, 'dismiss');
    return () => clearTimeout(t);
  }, [timeout, open]);
  return <ShowOf when={open} render={SnackbarImpl} {...props} />;
}
