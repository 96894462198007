import { useEffect, useReducer } from 'react';

const smallUISize = 724;
export const matchSmallUI = matchMedia(`(max-width: ${smallUISize - 1}px)`);
export const matchSidebarExpandable = matchMedia(`(min-width: 1023px)`);

/** Add's a listener to be called every time media query changes */
export function subscribeToMatchMedias(mediaQueryLists: MediaQueryList[], listener: (matches: boolean[]) => void) {
  const handleMatchChange = () => listener(mapMatches(mediaQueryLists));
  for (const mq of mediaQueryLists) mq.addEventListener('change', handleMatchChange);

  return () => {
    for (const mq of mediaQueryLists) mq.removeEventListener('change', handleMatchChange);
  };
}

const mapMatches = (mediaQueryLists: MediaQueryList[]) => mediaQueryLists.map(m => m.matches);
const reducer = (_: boolean[], e: boolean[]) => e;

export function useMediaQuery(...mediaQueryLists: MediaQueryList[]) {
  const [match, setMatch] = useReducer(reducer, mediaQueryLists, mapMatches);
  useEffect(() => subscribeToMatchMedias(mediaQueryLists, setMatch), mediaQueryLists);
  return match;
}
