/* eslint-disable no-console */
import { Service, ServiceInit } from 'rc-service';
import React from 'react';
import { immGet, TProp } from '../utils/immutable';
import { loadD3Locale, locales, TLocale } from '../utils/locale';
import { parseQueryMemo } from '../utils/url';
import { commonLanguageLoader } from '../i18n';
import { mergeDeep } from '../utils/merge';

export interface I18nFeature {
  name: string;
  root: boolean;
}

export interface I18nState {
  language: TLocale;
  i18n: any;
}

type LanguageLoaderFn = (lang: string, feat: string) => Promise<any>;
type FnTr = <T>(path: TProp, defaultValue?: T) => T;

const tagRe = /<(\d+)>(.*)<\/(\1)>/g;

interface I18nInitOptions {
  defaultLanguage: TLocale;
  features: I18nFeature[];
  languageLoader: LanguageLoaderFn;
}

export type I18nT = {
  (path: string, defaultValue: string, values?: { [key: string]: string | number }): string;
  (path: string, defaultValue?: string, values?: { [key: string]: string | number }): string | undefined;
  r: FnTr;
  c: (path: string, elements: React.ReactElement<any>[]) => any;
};

export class I18nService extends Service<I18nState, I18nInitOptions> {
  static serviceName = 'I18n';
  languageLoader: LanguageLoaderFn;
  features: I18nFeature[];

  constructor(init: ServiceInit, { defaultLanguage, features, languageLoader }: I18nInitOptions) {
    super(init);
    this.languageLoader = languageLoader;
    const queryLang = parseQueryMemo(location.search).lang as TLocale;
    const storageLang = localStorage.getItem('language') as TLocale;
    const language = queryLang in locales ? queryLang : storageLang in locales ? storageLang : defaultLanguage;
    this.features = features;
    this.state = {
      language,
      i18n: {},
    };
    this.setLanguage(language, true);
    this.setLanguage = this.setLanguage.bind(this);
  }

  async setLanguage(language: TLocale, force = false) {
    if (!force && language === this.state.language) return Promise.resolve();
    localStorage.setItem('language', language);
    await loadD3Locale(language);
    return this.fl(language, this.features);
  }

  loadFeature = (feature: I18nFeature) => this.fl(this.state.language, this.features.concat(feature));

  private async fl(language: TLocale, features: I18nFeature[]) {
    this.features = features;
    const i18n = await commonLanguageLoader(language);
    const fi = await Promise.all(features.map(feat => this.languageLoader(language, feat.name)));

    for (let i = 0; i < fi.length; i += 1) {
      const f = fi[i];
      mergeDeep(i18n, features[i].root ? f : { [features[i].name]: f });
    }

    this.setState({ language, i18n });
  }

  getT = (prefix = ''): I18nT => {
    const t = (path: string, defaultValue?: string, values?: { [key: string]: string }) => {
      const message = immGet(this.state.i18n, prefix ? `${prefix}.${path}` : path, defaultValue);
      // eslint-disable-next-line no-nested-ternary
      return (
        typeof message !== 'string'
          ? defaultValue
          : values
          ? Object.keys(values).reduce((txt, key) => txt.replace(`{${key}}`, values[key]), message)
          : message
      ) as string;
    };
    t.r = (path: string, defaultValue: any) => immGet(this.state.i18n, prefix ? `${prefix}.${path}` : path, defaultValue)!;
    t.c = (path: string, elements: React.ReactElement<any>[]) =>
      mapElements(immGet(this.state.i18n, prefix ? `${prefix}.${path}` : path), elements);
    return t;
  };

  t = this.getT();
  tr: FnTr = this.t.r;
  tc = this.t.c;
}

function mapElements(value: any, elements: React.ReactElement<any>[]) {
  if (!value || typeof value !== 'string') {
    if (!import.meta.env.PROD) console.warn('The translation key did not return a string', 'Perhaps an error on translation?', value);
    return null;
  }
  const parts = value.split(tagRe);
  if (parts.length === 1) return value;
  const tree = [];
  const els = elements.slice();

  let i = 0;

  while (i < parts.length) {
    if (parts[i] === parts[i + 2] && !isNaN(parseInt(parts[i], 16))) {
      const element = els.shift();
      if (element) tree.push(React.cloneElement(element, { key: els.length }, element?.props?.children ?? parts[i + 1]));
      else {
        if (!import.meta.env.PROD) console.warn(`Missing component for translation <${parts[i + 2]}>${parts[i + 1]}</${parts[i + 2]}>`);
        tree.push(parts[i + 1]);
      }
      i += 3;
    } else {
      if (parts[i]) tree.push(parts[i]);
      i += 1;
    }
  }
  if (els.length > 0)
    if (!import.meta.env.PROD)
      console.warn(`Passed more components than the available on translation`, 'Perhaps an error on translation?', value);
  return tree;
}
