import memoize from 'memoize-one';

export function parseQuery<T = Record<string, string>>(queryString: string): T {
  const query: any = {};
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (let i = 0; i < pairs.length; i += 1) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

export const parseQueryMemo = memoize(parseQuery) as typeof parseQuery;

export const linkTo = (url = '', options?: Record<string, string | number | undefined>) =>
  !options
    ? url
    : `${url}?${Object.keys(options)
        .filter(name => options[name])
        .map(name => `${name}=${encodeURIComponent(`${options[name]}`)}`)
        .join('&')}`;

export const withPrefix = (url: string) => BuildInfo.prefix + url;

export function linkToOrigin(url = '', options?: Record<string, string | number | undefined>) {
  return linkTo(location.origin + BuildInfo.prefix + url, options);
}

/**
 * Get a link on the origin or the full href if it is a full url
 * for use with VITE_APP_URL_* env variables
 */
export function linkOrOrigin(url: string) {
  return new URL(url, location.origin).href.replace(/\/$/, '');
}

export function isSameOrigin(url: string) {
  try {
    return new URL(url).origin === location.origin;
  } catch (e) {
    return false;
  }
}
