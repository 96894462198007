import React, { ComponentClass, FunctionComponent, ReactNode } from 'react';
import { ShowOf } from 'react-showof';

import { attachRipple } from '../utils/ripple';
import { setRef } from '../utils/reactUtils';
import { Tooltip, createNativeTooltipManager, TooltipOptions } from '../Tooltip';
import { createMenuSurfaceManager, MenuOptions, MenuSurface, MenuSurfaceManager } from '../MenuSurface';

export type ExtendedComponent = 'a' | 'button' | React.FunctionComponent<any> | React.ComponentClass<any>;
export type ButtonBaseProps<C extends ExtendedComponent = 'button'> = React.ComponentProps<C> & {
  component: C;
  buttonRef?: React.Ref<any>;
  ripple?: boolean;
  rippleUnbounded?: boolean;
  className?: string;
  tooltip?: React.ReactNode;
  tooltipOptions?: TooltipOptions;
  menu?: ComponentClass<{}, any> | FunctionComponent | ReactNode;
  menuOptions?: MenuOptions;
  menuClassname?: string;
  isCloseableOnClick?: boolean;
};

interface BaseButtonState {
  showTip: boolean;
}

export class BaseButton<T extends ExtendedComponent = 'button'> extends React.Component<ButtonBaseProps<T>, BaseButtonState> {
  static defaultProps = {
    component: 'button',
    ripple: true,
  };

  state = {
    showTip: false,
  };

  update = () => this.setState({});

  tip = createNativeTooltipManager(showTip => this.setState({ showTip }), this.props.tooltipOptions);
  menu?: MenuSurfaceManager;

  ref: React.Ref<HTMLButtonElement> = r => {
    setRef(this.props.buttonRef, r);
    if (this.props.ripple) attachRipple(this, r, { isUnbounded: this.props.rippleUnbounded });
    if (this.tip) this.tip.reference(r);
  };

  handleEvent: React.ReactEventHandler<HTMLElement> = e => {
    if (this.props.menu) {
      if (!this.menu) this.menu = createMenuSurfaceManager(this.update, this.props.isCloseableOnClick, this.props.menuOptions);
      this.menu.handleEvent(e);
    }
    if (this.props.onClick) this.props.onClick(e);
  };

  render() {
    const {
      component: Component,
      children,
      buttonRef,
      ripple,
      rippleUnbounded,
      tooltip,
      tooltipOptions,
      menu,
      menuOptions,
      menuClassname,
      isCloseableOnClick,
      ...otherProps
    } = this.props;

    if (Component === 'button') {
      // @ts-ignore
      otherProps.type = otherProps.type || 'button';
    } else {
      // @ts-ignore
      otherProps.role = 'button';
      // @ts-ignore
      otherProps['aria-disabled'] = otherProps.disabled;
    }

    return (
      <>
        <Component {...otherProps} onClick={this.handleEvent} ref={this.ref}>
          {children}
        </Component>
        {tooltip && <ShowOf render={Tooltip} when={this.state.showTip} message={tooltip} ref={this.tip.target} />}
        {menu && this.menu && (
          <ShowOf render={MenuSurface} when={this.menu.show} ref={this.menu.ref} className={menuClassname}>
            {menu}
          </ShowOf>
        )}
      </>
    );
  }
}
