import { RefObject, useEffect, useRef, useState } from 'react';

interface Size {
  height: number;
  width: number;
}

export function useElementSize(): [RefObject<HTMLDivElement>, Size?] {
  const [size, setSize] = useState<{ height: number; width: number }>();
  const elRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(_ => {
      if (elRef?.current?.clientHeight && elRef?.current?.clientWidth)
        setSize({ height: elRef?.current?.clientHeight, width: elRef?.current?.clientWidth });
    });

    if (elRef.current) {
      resizeObserver.observe(elRef.current);
    }
  }, [elRef]);

  return [elRef, size];
}
