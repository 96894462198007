/* eslint-disable react/no-access-state-in-setstate,no-nested-ternary */
import { createRef, RefObject, createContext, createElement } from 'react';
import { FocusTrap, createFocusTrap } from 'focus-trap';
import { Service, ServiceInit } from 'rc-service';
import { ScrollManager, ScrollManagerShape, ScrollState, defaultScrollState } from '@agroop/common/utils/ScrollManager';
import { createPortal } from 'react-dom';
import { immDelete, immToggle } from '@agroop/common/utils/immutable';
import { matchSidebarExpandable, subscribeToMatchMedias } from '../utils';

export type BeforeCloseHandler = (close: () => PromiseLike<unknown>, reason?: any) => void;
export type CloseHandler = (reason?: any) => void;

export interface SidebarOptions {
  onBeforeClose?: BeforeCloseHandler;
  onClose?: CloseHandler;
  closeable?: boolean;
  className?: string;
  style?: React.CSSProperties;
  scrim?: 'invisible' | 'blur';
  animated?: boolean;
  expandable?: boolean;
}

interface StackItem {
  ref: RefObject<HTMLDivElement>;
  options: SidebarOptions;
  closeable?: boolean;
  expanded?: boolean;
  id: number;
  render(children: React.ReactNode): React.ReactPortal | null;
}

export interface SidebarStackItem extends StackItem {
  close(reason?: any): void;
  scroll: ScrollState;
  ft?: FocusTrap;
  expanded: boolean;
  toggleExpanded(): void;
}

export interface SidebarState {
  isSidebarExpandable: boolean;
  closing: number;
  sidebars: Record<number, SidebarStackItem>;
  stack: number[];
  nextStackItem: StackItem;
  ready: boolean;
}

export interface SidebarService {
  scrollManager: ScrollManagerShape;
}

export class SidebarService extends Service<SidebarState> {
  static serviceName = 'Sidebar';

  constructor(init: ServiceInit, options: any) {
    super(init, options);
    // Keep track of scroll state on each opened sidebar
    this.scrollManager = ScrollManager(entries =>
      this.setState({
        sidebars: Object.fromEntries(
          Object.entries(this.state.sidebars).map(([id, sidebar]) => {
            const scroll = entries.find(e => e.target === sidebar.ref.current);
            return [id, scroll ? { ...sidebar, scroll: scroll.state } : sidebar];
          }),
        ),
      }),
    );

    const unsubscribeToMatchMedias = subscribeToMatchMedias([matchSidebarExpandable], ([isSidebarExpandable]) => {
      let sidebars = this.state.sidebars;
      if (!isSidebarExpandable)
        sidebars = Object.fromEntries(Object.entries(sidebars).map(([id, sidebar]) => [id, { ...sidebar, expanded: false }]));

      this.setState({ isSidebarExpandable, sidebars });
    });

    this.$onDispose = () => {
      this.scrollManager.disconnect();
      unsubscribeToMatchMedias();
    };

    this.state = {
      isSidebarExpandable: matchSidebarExpandable.matches,
      closing: -1,
      sidebars: {},
      stack: [],
      /**
       * Holds the reference for the placeholder that will be used when opening a new sidebar
       */
      nextStackItem: createStackItem(0),
      ready: false,
    };
  }

  deactivating: HTMLElement | null = null;

  setReady = (): void => this.setState({ ready: true });

  /**
   * Opens a new sidebar with the props specified
   * Adds this new sidebar to the stack and returns the item
   */
  openSidebar(options: SidebarOptions): SidebarStackItem {
    const stackItem = this.state.nextStackItem;
    let scroll = defaultScrollState;
    let ft: FocusTrap | undefined;
    if (stackItem.ref.current) {
      scroll = this.scrollManager.observe(stackItem.ref.current);
      ft = this.initFT(stackItem.ref.current);
    }
    const nextStackItem = createStackItem(stackItem.id + 1);
    const sidebar: SidebarStackItem = {
      ...stackItem,
      options,
      expanded: false,
      close: this.close,
      toggleExpanded: this.toggleExpanded,
      scroll,
      ft,
    };
    this.setState(
      {
        sidebars: { ...this.state.sidebars, [sidebar.id]: sidebar },
        stack: this.state.stack.concat(sidebar.id),
        nextStackItem,
      },
      { queue: true },
    );
    requestAnimationFrame(() => {
      if (!ft) {
        const sidebars = this.state.sidebars;
        ft = this.initFT(sidebar.ref.current!);
        this.setState({
          sidebars: {
            ...sidebars,
            [sidebar.id]: {
              ...sidebars[sidebar.id],
              ft,
              scroll: this.scrollManager.observe(sidebars[sidebar.id].ref.current!),
            },
          },
        });
      }
      this.updateFT();
    });

    return sidebar;
  }

  private initFT(el: HTMLElement) {
    return createFocusTrap(el, {
      escapeDeactivates: true,
      allowOutsideClick: () => true,
      fallbackFocus: el,
      onDeactivate: () => {
        if (el !== this.deactivating) this.close();
      },
    });
  }

  closeAll = () =>
    this.state.stack
      .slice()
      .reverse()
      .filter(id => !this.state.sidebars[id].expanded)
      .forEach((id, i) => setTimeout(this.closeById, i * 380, id));

  private updateFT() {
    const { stack, sidebars } = this.state;
    if (stack.length) {
      const sidebar = sidebars[stack[stack.length - 1]];
      if (sidebar.closeable) sidebar.ft?.activate();
    }
  }

  /** Remove the last item from stack */
  popStack = (sidebar: SidebarStackItem) => {
    this.scrollManager.unobserve(sidebar.ref.current!);
    this.deactivating = sidebar.ref.current;
    sidebar.ft?.deactivate();
    this.deactivating = null;
    this.setState({
      closing: -1,
      stack: this.state.stack.filter(id => id !== sidebar.id),
      sidebars: immDelete(this.state.sidebars, sidebar.id),
    });
    this.updateFT();
  };

  /** Request the current sidebar to close */
  close = (reason?: any) => this.closeById(this.state.stack[this.state.stack.length - 1], reason);

  toggleExpanded = () => {
    const { stack, sidebars } = this.state;
    this.setState({ sidebars: immToggle(sidebars, [stack[stack.length - 1], 'expanded']) });
  };

  private closeById = (id: number, reason?: any) => {
    const { stack, sidebars } = this.state;

    const closing = stack.indexOf(id);
    const options = sidebars[id].options;
    if (options.onClose) {
      this.setState({ closing });
      if (options.animated === false) options.onClose(reason);
      else setTimeout(options.onClose, 300, reason);
    } else if (options.onBeforeClose)
      options.onBeforeClose(() => {
        this.setState({ closing });
        return new Promise(resolve => setTimeout(resolve, 300));
      }, reason);
  };
}

function createStackItem(id: number): StackItem {
  return {
    options: {},
    ref: createRef<HTMLDivElement>(),
    id,
    render: renderSidebar,
  };
}

/**
 * Return the current stack of sidebars
 * Including the ones currently open and the placeholder for the next one
 */
export const mapSidebarStackItems = (state: SidebarState) =>
  state.stack.map<StackItem>(id => state.sidebars[id]).concat(state.nextStackItem);

export const SidebarContext = createContext<SidebarStackItem>(null as any);

function renderSidebar(this: SidebarStackItem, children: React.ReactNode): React.ReactPortal | null {
  return this.ref.current && createPortal(createElement(SidebarContext.Provider, { value: this }, children), this.ref.current);
}
