import React, { useContext, useEffect, useMemo } from 'react';
import cn from 'clsx';
import { ShowOfComponentProps } from 'react-showof';
import './Dialog.scss';
import uuid from '../utils/uuid';
import { Button, ButtonProps } from '../Button/Button';

export type BaseDialogProps = ShowOfComponentProps<{
  id?: string;
  scrollable?: boolean;
  stacked?: boolean;
  children?: React.ReactNode;
  className?: string;
}>;

export const BaseDialog = React.forwardRef<HTMLDivElement, BaseDialogProps>(
  ({ when, state, onTransitionEnd, id, scrollable, stacked, children, className }, ref) => {
    const dialogId = useMemo(() => id ?? uuid(), [id]);

    useEffect(() => {
      document.body.classList.toggle('mdc-dialog-scroll-lock', when);
      return () => document.body.classList.remove('mdc-dialog-scroll-lock');
    }, [when]);

    return (
      <aside
        id={dialogId}
        className={cn('mdc-dialog', `mdc-dialog--${state === 'exit' ? 'closing' : 'opening'}`, {
          'mdc-dialog--open': state === 'enter',
          'mdc-dialog--scrollable': scrollable,
          'mdc-dialog--stacked': stacked,
        })}
        role="alertdialog"
        aria-modal
        aria-labelledby={`${dialogId}-label`}
        aria-describedby={`${dialogId}-description`}
      >
        <div className="mdc-dialog__container" onTransitionEnd={onTransitionEnd} ref={ref}>
          <div className={cn('mdc-dialog__surface', className)}>
            <DialogIdContext.Provider value={dialogId}>{children}</DialogIdContext.Provider>
          </div>
        </div>
        <div className="mdc-dialog__scrim" />
      </aside>
    );
  },
);

const DialogIdContext = React.createContext('default-dialog-id');

export function DialogTitle(props: { title: any }) {
  const id = useContext(DialogIdContext);

  return (
    <h2 id={`${id}-label`} className="mdc-dialog__title">
      {props.title}
    </h2>
  );
}

export function DialogContent(props: { children: React.ReactNode; className?: string }) {
  const id = useContext(DialogIdContext);

  return (
    <section id={`${id}-description`} className={`mdc-dialog__content ${props.className ?? ''}`}>
      {props.children}
    </section>
  );
}

export function DialogButtons({ children }: { children: DialogButtonChildren }) {
  return (
    <footer className="mdc-dialog__actions">
      {getChildButtons(children).map((child, index) => (
        <React.Fragment key={index}>
          {React.cloneElement(child, { className: cn('mdc-dialog__button', child.props.className) })}
        </React.Fragment>
      ))}
    </footer>
  );
}

type DialogButtonElement = React.ReactElement<ButtonProps & { className?: string }>;

export type DialogButtonChildren = React.ReactElement | undefined | null | false | DialogButtonChildren[];

function getChildButtons(children: DialogButtonChildren, arr: DialogButtonElement[] = []): DialogButtonElement[] {
  if (children) {
    if (Array.isArray(children)) children.map(child => getChildButtons(child, arr));
    else if (children.type === React.Fragment) getChildButtons(children.props.children, arr);
    else if (children.type === Button) arr.push(children as DialogButtonElement);
    else if (process.env.NODE_ENV !== 'production') throw new Error('Invalid children passed to Dialog buttons, should be of type Button');
  }
  return arr;
}
