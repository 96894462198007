import React from 'react';
import { IcClose, IcArrowBack } from '@agroop/icons';
import IconButton from '@agroop/ui/lib/IconButton';
import { IconElement } from '@agroop/ui/lib/utils/reactUtils';
import { Header, HeaderProps } from '../components/Header';
import { matchSmallUI, useMediaQuery } from '../utils';
import { useSidebarCtx } from './Sidebar';

interface SidebarHeaderProps extends Omit<HeaderProps, 'isSmallUI' | 'scrollState'> {
  closeIcon?: IconElement;
  closeIconTooltip?: string;
}

export function SidebarHeader({ closeIcon, closeIconTooltip, ...props }: SidebarHeaderProps) {
  const [isSmallUI] = useMediaQuery(matchSmallUI);
  const sidebar = useSidebarCtx();

  return (
    <Header
      {...props}
      navigationIcon={
        sidebar.closeable && (
          <IconButton
            tooltip={closeIconTooltip}
            icon={closeIcon ?? (isSmallUI ? <IcArrowBack /> : <IcClose />)}
            onClick={() => sidebar.close()}
          />
        )
      }
      isSmallUI={isSmallUI}
      scrollState={sidebar.scroll}
    />
  );
}
