import React from 'react';
import cn from 'clsx';

interface LineRippleProps {
  active: boolean;
}

export const LineRipple: React.FC<LineRippleProps> = ({ active }) => (
  <div className={cn('mdc-line-ripple', { 'mdc-line-ripple--active': active })} />
);
