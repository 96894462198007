import React from 'react';
import cn from 'clsx';

interface ButtonBarProps {
  className?: string;
  children?: React.ReactNode;
  large?: boolean;
  padded?: boolean;
  disabled?: boolean;
  align?: 'start' | 'center' | 'end';
}

export const ButtonBar: React.FC<ButtonBarProps> = ({ className, children, large, padded, disabled, align = 'end', ...props }) => (
  <div className={cn('ButtonBar', className, { large, padded }, `align-${align}`)} {...props}>
    {disabled ? React.Children.map(children, el => React.cloneElement(el as any, { disabled })) : children}
  </div>
);
