import { PathToRe, RouterCtx } from './types';
import { navigate, resolve } from './router';

const cachedPaths: Record<string, PathToRe> = Object.create(null);

const propRE = /:\w+/g;

export function pathToRe(path: string): PathToRe {
  if (!cachedPaths[path]) {
    const wcEnd = path.substr(-1) === '*';
    const prefix = path.substr(0, 1) === '*' ? '' : '^';
    const uri = path.replace(propRE, '([^/]+)').replace(/\*/g, '');
    const re = new RegExp(`${prefix}(${uri})${wcEnd ? `(.*)` : `$`}`);

    const match = path.match(propRE);
    const props = match ? match.map((p: string) => p.substr(1)) : [];
    if (wcEnd) props.push('$');
    const basePath = wcEnd ? path.slice(0, -1) : path;

    cachedPaths[path] = [re, props, basePath];
  }

  return cachedPaths[path];
}

const cachedMatches: Record<string, RouterCtx<any> | null> = Object.create(null);

function doMatch<P extends any>(path: string, currentUri: string, parent?: RouterCtx<P>): RouterCtx<P> | null {
  const [re, props, basePath] = pathToRe(path);

  const uriMatch = re.exec(currentUri);
  if (!uriMatch) return null;

  const uri = uriMatch.shift()!;
  const pathname = uriMatch.shift() || '/';

  return {
    basePath,
    uri, // matched uri
    pathname,
    parent,
    params: props.reduce((acc, p) => ({ ...acc, [p]: uriMatch.shift() }), {}) as P,
    resolve: to => resolve(to, pathname),
    navigate: (to, replace, state, noInterception) => navigate(resolve(to, pathname), replace, state, noInterception),
    // eslint-disable-next-line no-underscore-dangle
    goBack: () => (history.state && history.state._prev === parent!.pathname ? history.back() : navigate(parent!.pathname, true)),
  };
}

export function matcher<P extends any>(path: string, uri: string, parent?: RouterCtx<P>): RouterCtx<P> | null {
  const key = `${path}__${uri}`;
  return key in cachedMatches ? cachedMatches[key] : (cachedMatches[key] = doMatch<P>(path, uri, parent));
}
