import React, { FocusEventHandler } from 'react';

export type ValidationFunction<FD = any> = (value: any, name: string, form: FormCtx<FD>) => false | string;
export type InternalValidationFunction<FD = any> = (value: any, name: string, form: FormCtx<FD>) => boolean | undefined | null;

export type FormSubmitFn<FD = any> = (formData: FD) => Promise<any> | any;
export type MapErrorsFn<FD = any> = (result: any, form: FormCtx<FD>) => { name: string; error: string }[];
export type OnSubmitErrorFn<FD = any> = (result: any, form: FormCtx<FD>) => void;
export type OnValidationFailedFn<FD = any> = (fields: string[], form: FormCtx<FD>) => void;

export interface FieldState {
  name: string;
  dirty: boolean;
  valid: boolean;
  focused: boolean;
  required: boolean;
  error: string | false; // todo set error type
  validations?: ValidationFunction[]; // todo set validations type
}

export interface SetValueOptions {
  markDirty?: boolean;
  validate?: boolean;
}
export interface FormCtx<FD = any> {
  options: UseFormOptions<FD>;
  formData: FD;
  initialFormData: FD;
  fields: { [name: string]: FieldState };
  focusedField?: string;
  dirty: boolean;
  submitting: boolean;
  registerField(field: FieldState, defaultValue: any): void;
  unregisterField(name: string): void;
  setFieldState(name: string, fieldState: Partial<FieldState>): void;

  getValue<V = any>(name: string, defaultValue?: V): V;
  setValue(name: string, value: any, options?: SetValueOptions): void;
  setValues(values: { [key: string]: any }, options?: SetValueOptions): void;
  setFormData(formData: FD): void;
  deleteValue(name: string): void;

  array(name: string): any[];
  arrayMap(name: string, callback: (path: string, index: number, value: any) => any): any[];
  arrayPush(name: string, obj?: any): number;
  arrayRemove(name: string, index: number): void;
  objectMap(name: string, callback: (path: string, key: string, value: any) => any): any[];

  handleSubmit(e: React.FormEvent<HTMLFormElement>): void;
  submit(extraFormData?: Partial<FD>): PromiseLike<any> | null;

  handleReset(e: React.FormEvent<HTMLFormElement>): void;
  reset(): void;

  validateField(name: string, value?: any): string | false;

  handleInputFocus: FocusEventHandler<HTMLInputElement>;
  handleInputBlur: FocusEventHandler<HTMLInputElement>;
  Provider: (
    props: React.PropsWithChildren<React.FormHTMLAttributes<HTMLFormElement>>,
  ) => React.FunctionComponentElement<React.ProviderProps<FormCtx<FD>>>;
}

export const FormContext = React.createContext<FormCtx<any>>({} as any);

export interface UseFormOptions<FD> {
  onSubmit: FormSubmitFn<FD>;
  onAfterSubmit?: (data: any) => void;
  resetAfterSubmit?: boolean;
  formData?: Partial<FD>;
  mapErrors?: MapErrorsFn;
  onValidationFailed?: OnValidationFailedFn;
  onSubmitError?: OnSubmitErrorFn;
}
