import React, { ReactElement } from 'react';

import cn from 'clsx';

import styles from './EllipsisScroller.module.scss';

interface EllipsisScrollerProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const ellipsisEnter: React.MouseEventHandler<HTMLDivElement> = e => {
  const target = e.currentTarget;
  const diff = target.scrollWidth - target.offsetWidth;

  // todo: review this later on. It works but not the best way
  if (diff > 0) {
    let x = 0;
    const move = () => {
      if (document.activeElement !== target) {
        target.scrollTo({ left: (x += 1) });
        // @ts-ignore
        if (x < diff) target.$raf = requestAnimationFrame(move);
      }
    };

    move();
  }
};

const ellipsisLeave: React.MouseEventHandler<HTMLDivElement> = e => {
  // @ts-ignore
  cancelAnimationFrame(e.currentTarget.$raf);

  e.currentTarget.scrollTo({ left: 0 });
};

export default function EllipsisScroller({ children, className, style }: EllipsisScrollerProps) {
  return (
    <div className={cn(styles.scroller, className)} style={style} onMouseEnter={ellipsisEnter} onMouseLeave={ellipsisLeave}>
      {children}
    </div>
  );
}

export function EllipsisScrollerInput({ children, className }: EllipsisScrollerProps) {
  const child =
    React.isValidElement(children) &&
    React.cloneElement(children as ReactElement, {
      onMouseEnter: ellipsisEnter,
      onMouseLeave: ellipsisLeave,
    });

  return (
    <div className={cn(styles.scroller, className)} style={{ width: '100%' }}>
      {child}
    </div>
  );
}
