/**
 * Auto-generated, do not edit, it will be overwritten
 * https://github.com/porfirioribeiro/openapi2ts
 */
import { request, ResponsePromise } from '..';
import * as types from './types';

export interface HandleUnlinkProviderOptions {
  email: string;
  redirect: string;
}

export const handleUnlinkProvider = (options: HandleUnlinkProviderOptions): ResponsePromise<unknown, HandleUnlinkProviderOptions> =>
  request({
    module: 'accounts',
    method: 'POST',
    uri: '/authentication/handleUnlinkProvider',
    query: { email: options.email, redirect: options.redirect },
    security: null,
    options,
  });

export interface ResetPasswordOptions {
  email: string;
  redirect: string;
}

export const resetPassword = (options: ResetPasswordOptions): ResponsePromise<unknown, ResetPasswordOptions> =>
  request({
    module: 'accounts',
    method: 'POST',
    uri: '/authentication/resetPassword',
    query: { email: options.email, redirect: options.redirect },
    security: null,
    options,
  });

export interface SignupWithEmailAndPasswordOptions {
  body: types.SignupWithEmailAndPasswordRequest;
}

export const signupWithEmailAndPassword = (
  options: SignupWithEmailAndPasswordOptions,
): ResponsePromise<types.SignupUserResponse, SignupWithEmailAndPasswordOptions> =>
  request({
    module: 'accounts',
    method: 'POST',
    uri: '/authentication/signupWithEmailAndPassword',
    body: options.body,
    security: null,
    options,
  });

export interface SignupWithTokenOptions {
  body: types.SignupWithTokenRequest;
}

export const signupWithToken = (options: SignupWithTokenOptions): ResponsePromise<types.SignupUserResponse, SignupWithTokenOptions> =>
  request({
    module: 'accounts',
    method: 'POST',
    uri: '/authentication/signupWithToken',
    body: options.body,
    security: null,
    options,
  });

export interface ValidateEmailAndPasswordOptions {
  body: types.ValidateEmailAndPasswordRequest;
}

export const validateEmailAndPassword = (
  options: ValidateEmailAndPasswordOptions,
): ResponsePromise<boolean, ValidateEmailAndPasswordOptions> =>
  request({
    module: 'accounts',
    method: 'POST',
    uri: '/authentication/validateEmailAndPassword',
    body: options.body,
    security: null,
    options,
  });

export interface ValidateFirebaseTokenOptions {
  token: string;
  createCustomToken?: boolean;
}

export const validateFirebaseToken = (
  options: ValidateFirebaseTokenOptions,
): ResponsePromise<types.ValidatedUserResponse, ValidateFirebaseTokenOptions> =>
  request({
    module: 'accounts',
    method: 'POST',
    uri: '/authentication/validateFirebaseToken',
    query: { token: options.token, createCustomToken: options.createCustomToken },
    security: null,
    options,
  });
