/**
 * Return a new array without this value
 * @param arr
 * @param item
 */
import shallowEqual from './shallowEqual';

export function arrayIncludes<T>(arr: T[], item: T | T[]): boolean {
  return Array.isArray(item) ? item.every(i => arr.includes(i)) : arr.includes(item);
}

export function arrayWith<T>(arr: T[], item: T | T[]): T[] {
  if (Array.isArray(item)) return item.reduce(arrayWith, arr);

  return arr.includes(item) ? arr : arr.concat(item);
}

export function arrayWithout<T>(arr: T[], item: T | T[]): T[] {
  if (Array.isArray(item)) return item.reduce(arrayWithout, arr);

  const idx = arr.indexOf(item);
  return idx < 0 ? arr : arr.slice(0, idx).concat(arr.slice(idx + 1));
}

export function arrayConcatIfNotUndefined<T>(arr: T[], item: T | T[] | undefined) {
  if (item) return arr.concat(item);
  return arr;
}

/**
 * returns a new array with or without the item
 * @param arr
 * @param item
 */
export function arrayToggle<T>(arr: T[], item: T | T[]): T[] {
  if (Array.isArray(item)) return item.reduce(arrayToggle, arr);

  const idx = arr.indexOf(item);
  return idx !== -1 ? arr.slice(0, idx).concat(arr.slice(idx + 1)) : arr.concat(item);
}

export function arrayDiff<T>(arrA: T[], arrB: T[]): T[] {
  return arrA.filter(x => !arrB.includes(x)).concat(arrB.filter(x => !arrA.includes(x)));
}

export function arraySameValues<T>(arrA: T[], arrB: T[]): boolean {
  return arrA.length === arrB.length && !arrA.some(x => !arrB.includes(x));
}

export function arraySwapIndex<T>(arr: T[], a: number, b: number): T[] {
  const copy = arr.slice(0);
  [copy[a], copy[b]] = [copy[b], copy[a]];
  return copy;
}

export function arraySwap<T>(arr: T[], a: T, b: T): T[] {
  return arraySwapIndex(arr, arr.indexOf(a), arr.indexOf(b));
}

export function arrayMoveMutate<T>(array: T[], from: number, to: number) {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
}

export function arrayMove<T>(array: T[], from: number, to: number): T[] {
  const ret = array.slice();
  arrayMoveMutate(ret, from, to);
  return ret;
}

export function addIndexArray<T>(array: T[], toIndex: number, item: T): T[] {
  const newArray = array.concat(item);
  arrayMoveMutate(newArray, newArray.length - 1, toIndex);
  return newArray;
}

/**
 * returns a new array with or without the item at the index specified
 * @param arr
 * @param index
 */
export function arrayWithoutIndex<T>(arr: T[], index: number) {
  return arr.slice(0, index).concat(arr.slice(index + 1));
}

export function arrayOfObjectShallowEquals<T>(arr1: T[], arr2: T[], ignore?: string | string[]): boolean {
  if (Object.is(arr1, arr2)) return true;
  if (arr1.length !== arr2.length) return false;

  return arr1.every((it, i) => shallowEqual(it, arr2[i], ignore));
}

/**
 * returns a new array with the union of two initial arrays filtering out the items where the key is present in the first array
 * @param arr1
 * @param arr2
 * @param key
 */
export function arrayUnionBy<T extends { [k: string]: any }>(arr1: T[], arr2: T[], key: keyof T): T[] {
  return arr2.concat(arr1).filter(function filter(this: Set<T>, o) {
    return this.has(o[key]) ? false : this.add(o[key]);
  }, new Set());
}

/**
 * Find an item in one array that match the predicate in reverse order
 * @param array
 * @param predicate
 */
export function arrayFindLast<T>(array: T[], predicate: (value: T, index: number, _array: T[]) => unknown): T | undefined {
  // eslint-disable-next-line no-plusplus
  for (let i = array.length - 1; i >= 0; --i) {
    const x = array[i];
    if (predicate(x, i, array)) return x;
  }
  return undefined;
}

/** given an array and a current index, return the previous index or the last if overflowing */
export const arrayPrevIndex = (array: any[], current: number) => (current === 0 ? array.length - 1 : current - 1);
/** given an array and a current index, return the next index or the first if overflowing */
export const arrayNextIndex = (array: any[], current: number) => (current + 1) % array.length;

export function uniqBy<T extends { [k: string]: any }>(array: T[], k: keyof T) {
  return [...new Map(array.map(item => [item[k], item])).values()];
}

export const uniqueArray = (array: any[]) =>
  array.filter((value, index) => {
    // eslint-disable-next-line no-underscore-dangle
    const _value = JSON.stringify(value);
    return (
      index ===
      array.findIndex(item => {
        return JSON.stringify(item) === _value;
      })
    );
  });

export function arraysEqual(a: any[], b: any[]) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  const aSorted = [...a].sort();
  const bSorted = [...b].sort();

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < a.length; i++) {
    if (aSorted[i] !== bSorted[i]) return false;
  }

  return true;
}
