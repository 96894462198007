import { createPopper, Modifier } from '@popperjs/core/lib/popper-lite';
import flip from '@popperjs/core/lib/modifiers/flip';
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow';

import { SelectProps, SelectState } from './Select';
import { TextFieldProps } from '../TextField/TextField';

/* eslint-disable no-param-reassign */
const size: Modifier<'size', {}> = {
  name: 'size',
  enabled: true,
  phase: 'main',
  fn: ({ state }) => {
    if (state.rects.popper.width < state.rects.reference.width) state.styles.popper.width = `${state.rects.reference.width}px`;

    return state;
  },
};

export function getPopper(reference: Element, r: HTMLElement) {
  return createPopper(reference, r, {
    strategy: 'fixed',
    placement: 'bottom-start',
    modifiers: [flip, preventOverflow, size],
  });
}

export function mapState(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  options: any[] = [],
  value: any,
  props: SelectProps,
): Pick<SelectState, 'selectedItem' | 'inputValue' | 'options'> {
  const selectedItem =
    (value &&
      options.find((i: any) =>
        typeof i === 'string' || typeof i === 'number' ? i === value : i[props.valueKey!] === (value[props.valueKey!] || value),
      )) ||
    null;
  const inputValue = props.itemToString!(selectedItem, props.labelKey!);
  return { selectedItem, inputValue, options };
}

// eslint-disable-next-line no-nested-ternary
export const itemToString = (i: any, key: string) => (typeof i === 'string' ? i : i ? i[key!] : '');

export const hasOwnProperty = Object.prototype.hasOwnProperty;

export interface BaseSelectProps<T = any>
  extends Omit<TextFieldProps, 'textArea' | 'trailingIcon' | 'leadingIcon' | 'type' | 'autoComplete' | 'onChange' | 'onSelect'> {
  label?: string;
  value?: any;
  // Label
  itemToString?: (value: T, key: string) => string;
  renderOption?: (value: T, index: number) => React.ReactNode;
}

export function scrollIntoView(node?: HTMLElement) {
  if (node) node.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
}
