/**
 * Auto-generated, do not edit, it will be overwritten
 * https://github.com/porfirioribeiro/openapi2ts
 */
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { request, ResponsePromise } from '..';
import * as types from './types';

export const getIrrigationSystemDataResponseList = (): ResponsePromise<types.IrrigationSystemDataResponse[], any> =>
  request({
    module: 'commons',
    method: 'GET',
    uri: '/irrigationSystem',
  });

export const getIrrigationSystemDataResponseListKey = 'getIrrigationSystemDataResponseList';

export const useGetIrrigationSystemDataResponseList = <TData = types.IrrigationSystemDataResponse[]>(
  queryConfig?: Partial<UseQueryOptions<types.IrrigationSystemDataResponse[], unknown, TData>>,
) =>
  useQuery({
    queryFn: async () => getIrrigationSystemDataResponseList(),
    queryKey: [getIrrigationSystemDataResponseListKey],
    ...queryConfig,
  });

export interface GetIrrigationSystemDataResponseByIdOptions {
  id: string;
}

export const getIrrigationSystemDataResponseById = (
  options: GetIrrigationSystemDataResponseByIdOptions,
): ResponsePromise<types.IrrigationSystemDataResponse, GetIrrigationSystemDataResponseByIdOptions> =>
  request({
    module: 'commons',
    method: 'GET',
    uri: '/irrigationSystem/{id}',
    path: { id: options.id },
    options,
  });

export const getIrrigationSystemDataResponseByIdKey = 'getIrrigationSystemDataResponseById';

export const useGetIrrigationSystemDataResponseById = <TData = types.IrrigationSystemDataResponse>(
  options: GetIrrigationSystemDataResponseByIdOptions,
  queryConfig?: Partial<UseQueryOptions<types.IrrigationSystemDataResponse, unknown, TData>>,
) =>
  useQuery({
    queryFn: async () => getIrrigationSystemDataResponseById(options),
    queryKey: [getIrrigationSystemDataResponseByIdKey, options.id],
    ...queryConfig,
  });
