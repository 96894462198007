/**
 * Auto-generated, do not edit, it will be overwritten
 * https://github.com/porfirioribeiro/openapi2ts
 */
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { request, ResponsePromise } from '..';
import * as types from './types';

export const getTypologyListByLocale = (): ResponsePromise<types.IdNameResponse[], any> =>
  request({
    module: 'commons',
    method: 'GET',
    uri: '/typology',
  });

export const getTypologyListByLocaleKey = 'getTypologyListByLocale';

export const useGetTypologyListByLocale = <TData = types.IdNameResponse[]>(
  queryConfig?: Partial<UseQueryOptions<types.IdNameResponse[], unknown, TData>>,
) => useQuery({ queryFn: async () => getTypologyListByLocale(), queryKey: [getTypologyListByLocaleKey], ...queryConfig });
