import React from 'react';
import cn from 'clsx';

import { BaseButton, ButtonBaseProps, ExtendedComponent } from '../Button/BaseButton';
import { IconElement, renderIcon } from '../utils/reactUtils';

export interface IconButtonProps {
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  on?: boolean;
  icon: IconElement;
  iconOn?: IconElement;
  dense?: boolean;
}

function IconButton<T extends ExtendedComponent = 'button'>({
  component,
  className,
  children,
  on,
  icon,
  iconOn,
  dense,
  ...props
}: ButtonBaseProps<T> & IconButtonProps) {
  return (
    <BaseButton
      component={component}
      className={cn('mdc-icon-button', { 'mdc-icon-button--dense': dense }, className)}
      tabIndex={0}
      {...(props as any)}
    >
      <div className="mdc-icon-button__ripple" />
      {children}
      {renderIcon(on && iconOn ? iconOn : icon, 'mdc-icon-button__icon')}
    </BaseButton>
  );
}

IconButton.defaultProps = {
  component: 'button',
  ripple: true,
  rippleUnbounded: true,
};

export default IconButton;
