/* eslint-disable react/no-access-state-in-setstate,no-sequences */
import { ReactNode } from 'react';
import { Service } from 'rc-service';

import { arrayWithout } from '@agroop/common/utils/array';
import { CloseReason, SnackbarSeverities } from '@agroop/ui/lib/Snackbar';

interface PopupButton {
  name: string;
  text?: string;
}

export interface Popup {
  closeable?: boolean;
  title?: string;
  message?: ReactNode;
  buttons?: PopupButton[];
  onClose: () => void;
  onButtonClick: (name: string, e: React.MouseEvent) => void;
}

export interface SnackbarItem {
  message: string;
  action?: string;
  timeout?: number;
  stacked?: boolean;
  leading?: boolean;

  onClose(reason: CloseReason): void;
  severity?: SnackbarSeverities;
}

interface UiState {
  popups: Popup[];
  popupOpen: boolean;
  snackbars: SnackbarItem[];
}

type AlertOptions = string | { title: string; message: ReactNode };

export class UiService extends Service<UiState> {
  static serviceName = 'Ui';
  static POPUP_OK = 'ok';
  static POPUP_CANCEL = 'cancel';
  state: UiState = {
    popups: [],
    popupOpen: false,
    snackbars: [],
  };

  private popupResolvers: (() => void)[] = [];

  showPopup = (popup: Partial<Popup>) =>
    new Promise<string>(resolve =>
      this.setState({
        popups: [...this.state.popups, { ...popup, onClose: resolve as any, onButtonClick: resolve }],
        popupOpen: true,
      }),
    ).then(this.closePopup);

  closePopup = (d?: any) => {
    const popups = this.state.popups.slice(1);
    this.setState({ popups, popupOpen: false });
    if (popups.length) {
      setTimeout(() => this.setState({ popupOpen: true }), 100);
    } else if (this.popupResolvers.length > 0) {
      this.popupResolvers.forEach(r => r());
      this.popupResolvers = [];
    }
    return d;
  };

  showAlert = (options: AlertOptions) =>
    this.showPopup({
      ...(typeof options === 'string' ? { message: options } : options),
      buttons: [{ name: UiService.POPUP_OK }],
    });

  showConfirm = (options: AlertOptions): PromiseLike<boolean> =>
    this.showPopup({
      ...(typeof options === 'string' ? { message: options } : options),
      buttons: [{ name: UiService.POPUP_CANCEL }, { name: UiService.POPUP_OK }],
    }).then(button => button === UiService.POPUP_OK);

  waitForPopup = () => new Promise(resolve => this.popupResolvers.push(resolve as any));

  showSnackbar = (snackbar: Omit<SnackbarItem, 'onClose'>) => {
    return new Promise<CloseReason>(resolve => {
      const item: SnackbarItem = {
        ...snackbar,
        onClose: reason => {
          this.setState({ snackbars: arrayWithout(this.state.snackbars, item) });
          resolve(reason);
        },
      };
      this.setState({ snackbars: this.state.snackbars.concat(item) });
    });
  };
}
