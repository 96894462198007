import { Auth, onAuthStateChanged, signInWithCustomToken, signOut, User } from 'firebase/auth';
import { Service, ServiceInit } from 'rc-service';

import { TApps, UserResponse } from '@agroop/api/accounts/types';
import { validateFirebaseToken } from '@agroop/api/accounts/authentication';

import { required } from '../utils/check';
import { getObject } from '../utils/localStorage';
import { I18nService } from './I18n';
import { TLocale } from '../utils/locale';
import { firebaseAuth } from '../firebase';
import { parseQuery } from '../utils/url';
import { STOMPService } from './STOMP';

export type UserAppName = TApps;

const USER_STORAGE_KEY = 'userData';
const AUTH_STORAGE_KEY = 'userAuth';

export interface UserAuthState {
  fbUser: User | null;
  user?: UserResponse;
  authorities: string[];
  ready: boolean;
}

export class UserAuthService extends Service<UserAuthState> {
  static serviceName = 'UserAuth';
  readonly stomp: STOMPService;
  private readonly auth: Auth;

  constructor(init: ServiceInit) {
    super(init);
    this.auth = firebaseAuth;
    this.stomp = init.$ctx.get(STOMPService, null, this as UserAuthService);

    this.state = {
      ready: false,
      fbUser: null,
      user: getObject(USER_STORAGE_KEY),
      authorities: getObject(AUTH_STORAGE_KEY) ?? [],
    };

    // We only need this initializeWithQueryInDev when developing locally
    if (!import.meta.env.PROD) initializeWithQueryInDev.call(this);
    else onAuthStateChanged(firebaseAuth, this.validateFirebaseUser);
  }

  validateFirebaseUser = async (fbUser: User | null) => {
    let user: UserResponse | undefined;
    let authorities: string[] | undefined;
    if (fbUser) {
      const token = await fbUser.getIdToken();
      try {
        ({ user, authorities } = await validateFirebaseToken({ token }));
      } catch (e) {
        //
      }
    }
    await this.storeUser(fbUser, user, authorities);
  };

  get isLoggedIn() {
    return !(!firebaseAuth.currentUser || !this.state.user);
  }

  get user() {
    return this.state.user ?? required('Trying to access user without login');
  }

  get userId() {
    return this.state.user?.userId ?? required('Trying to access userId without login');
  }

  get tmz() {
    return this.state.user?.tmz ?? 'Europe/Lisbon';
  }

  /** logout and clear the user data */
  logout = () => signOut(this.auth);

  /** Verify the current token and refresh it if expired or not valid */
  verifyToken = () => firebaseAuth.currentUser?.getIdToken() ?? Promise.reject({ code: 401 });

  storeUser = async (fbUser?: User | null, user?: UserResponse, authorities?: string[]) => {
    if (authorities) localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(authorities));
    else localStorage.removeItem(AUTH_STORAGE_KEY);
    if (user) {
      localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
      await this.$ctx.get(I18nService).setLanguage(user.language as TLocale);
    } else localStorage.removeItem(USER_STORAGE_KEY);
    this.setState({ fbUser, user, authorities, ready: true });
  };
}

/**
 * while in dev, as we have multiple frontend dev servers running on different ports,
 * we need a way to `login` into this services, so we send a special `idToken`
 * from auth to authorize our app and store the tokens
 */
async function initializeWithQueryInDev(this: UserAuthService) {
  const query = parseQuery(location.search);
  if (query.idToken) {
    try {
      const userCredential = await signInWithCustomToken(firebaseAuth, query.idToken);
      await this.validateFirebaseUser(userCredential.user);
    } catch (e) {
      // we failed to validate the idToken, maybe it is invalid?
    }
  }
  onAuthStateChanged(firebaseAuth, this.validateFirebaseUser);
}
