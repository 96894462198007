import { timeFormatDefaultLocale } from 'd3-time-format';

export type TLocale = 'pt-PT' | 'pt-BR' | 'en-GB' | 'es-ES' | 'fr-FR';

type StripeLocale = 'en' | 'en-GB' | 'es' | 'es-ES' | 'fr' | 'fr-FR' | 'pt' | 'pt-BR';

interface ILocaleObject {
  match: RegExp;
  locale: string;
  name: string;
  /** Locale to use with d3-time-format: https://cdn.jsdelivr.net/npm/d3-time-format@2/locale/ */
  d3Locale?: string;
  stripe: StripeLocale;
}

export const locales: Record<TLocale, ILocaleObject> = {
  'pt-BR': {
    match: /^pt-BR$/i,
    locale: 'pt-BR',
    name: 'Português (Brasil)',
    stripe: 'pt-BR',
  },
  'pt-PT': {
    match: /^pt/i,
    locale: 'pt-PT',
    name: 'Português (Portugal)',
    d3Locale: 'pt-BR',
    stripe: 'pt',
  },
  'es-ES': {
    match: /^es/i,
    locale: 'es-ES',
    name: 'Español',
    stripe: 'es',
  },
  'en-GB': {
    match: /^en/i,
    locale: 'en-GB',
    name: 'English',
    stripe: 'en-GB',
  },
  'fr-FR': {
    match: /^fr/i,
    locale: 'fr-FR',
    name: 'Français',
    stripe: 'fr-FR',
  },
};

export const defaultLanguage: TLocale = 'en-GB';

export const languages: TLocale[] = ['en-GB', 'pt-PT', 'pt-BR', 'es-ES', 'fr-FR'];

export function getLanguageForNavigator(qlang?: TLocale): TLocale {
  if (qlang && locales[qlang]) return qlang;
  const navLanguages = navigator.languages || [navigator.language];
  for (let i = 0; i < navLanguages.length; i += 1) {
    for (let j = 0; j < languages.length; j += 1) {
      if (locales[languages[j]].match.test(navLanguages[i])) return languages[j];
    }
  }
  return defaultLanguage;
}

export function localeToString(lang: TLocale) {
  return `${locales[lang].name} - ${locales[lang].locale}`;
}

export async function loadD3Locale(language: TLocale) {
  const d3Locale = locales[language].d3Locale ?? language;
  try {
    const tfl = await fetch(`https://cdn.jsdelivr.net/npm/d3-time-format@2/locale/${d3Locale}.json`).then(r => r.json());
    timeFormatDefaultLocale(tfl);
  } catch (e) {
    // eslint-disable-next-line no-console
    if (!import.meta.env.PROD) console.warn(`Failed to load d3-time-format locale: ${d3Locale} for locale: ${language}`);
  }
}
