import React from 'react';
import cn from 'clsx';

interface TopAppBarProps extends React.HTMLProps<HTMLHeadingElement> {
  dense?: boolean;
  fixed?: boolean;
  prominent?: boolean;
  short?: boolean;
  shortCollapsed?: boolean;
  scrolled?: boolean;
}

export const TopAppBar: React.FC<TopAppBarProps> = ({
  className,
  dense,
  fixed,
  scrolled,
  prominent,
  short,
  shortCollapsed,
  children,
  ...props
}) => (
  <header
    className={cn('mdc-top-app-bar', className, {
      'mdc-top-app-bar--fixed': fixed,
      'mdc-top-app-bar--fixed-scrolled': fixed && scrolled,
      'mdc-top-app-bar--short': shortCollapsed || short,
      'mdc-top-app-bar--short-collapsed': shortCollapsed,
      'mdc-top-app-bar--prominent': prominent,
      'mdc-top-app-bar--dense': dense,
    })}
    {...props}
  >
    {children}
  </header>
);
