import React from 'react';
import CircularProgress, { ICircularProgressProps } from '../CircularProgress/CircularProgress';

interface ActivityIndicatorProps extends Omit<ICircularProgressProps, 'progress'> {
  delay?: number;
}

export default class ActivityIndicator extends React.Component<ActivityIndicatorProps> {
  static defaultProps = {
    delay: 300,
    center: true,
    width: 76,
    height: 76,
  };

  state = {
    show: !this.props.delay,
  };

  timer: any;
  componentDidMount() {
    if (this.props.delay) this.timer = setTimeout(() => this.setState({ show: true }), this.props.delay);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const { delay, ...props } = this.props;
    return this.state.show ? <CircularProgress {...props} /> : null;
  }
}
