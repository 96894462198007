/* eslint-disable jsx-a11y/label-has-for,no-param-reassign */
import React from 'react';

import cn from 'clsx';
import './Checkbox.scss';
import uuid from '../utils/uuid';
import { attachRipple } from '../utils/ripple';

export interface ICheckboxProps {
  checked?: boolean;
  indeterminate?: boolean;
  /**
   * primary
   * @default true
   */
  primary?: boolean;
  disabled?: boolean;
  className?: string;
  name?: string;
  id?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onClick?: React.MouseEventHandler<HTMLElement>;
  label?: React.ReactNode;
  alignEnd?: boolean;
  inline?: boolean;
}

const noop = () => {};

class Checkbox extends React.Component<ICheckboxProps> {
  id = this.props.id || uuid();
  private assignRipple: React.Ref<any> = r => attachRipple(this, r, { isUnbounded: true });

  render() {
    const {
      checked,
      indeterminate = false,
      onChange = noop,
      onClick,
      primary = true,
      disabled,
      className,
      name,
      label,
      alignEnd,
      inline,
    } = this.props;
    const WrapperElem: any = label ? 'div' : 'label';
    const checkBox = (
      <WrapperElem
        ref={this.assignRipple}
        className={cn('mdc-checkbox', { 'mdc-checkbox--primary': primary, 'mdc-checkbox--disabled': disabled }, className)}
        onClick={label ? undefined : onClick}
      >
        <input
          type="checkbox"
          id={this.id}
          name={name}
          className="mdc-checkbox__native-control"
          onChange={onChange}
          checked={checked}
          disabled={disabled}
          aria-checked={indeterminate ? 'mixed' : undefined}
          ref={r => r && (r.indeterminate = !checked && indeterminate)}
        />
        <div className="mdc-checkbox__background">
          <svg className="mdc-checkbox__checkmark" viewBox="0 0 24 24">
            <path className="mdc-checkbox__checkmark-path" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59" />
          </svg>
          <div className="mdc-checkbox__mixedmark" />
        </div>
      </WrapperElem>
    );

    return label ? (
      <div
        className={cn('mdc-form-field', { 'mdc-form-field--align-end': alignEnd, 'mdc-form-field--block': !inline })}
        onClick={label ? onClick : undefined}
      >
        {checkBox}
        <label htmlFor={this.id}>{label}</label>
      </div>
    ) : (
      checkBox
    );
  }
}

export default Checkbox;
