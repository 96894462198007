/**
 * Auto-generated, do not edit, it will be overwritten
 * https://github.com/porfirioribeiro/openapi2ts
 */
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { request, ResponsePromise } from '..';
import * as types from './types';

export interface GetAllWeedsOptions {
  cropId?: string;
}

export const getAllWeeds = (options: GetAllWeedsOptions): ResponsePromise<types.PlagueWithCropResponse[], GetAllWeedsOptions> =>
  request({
    module: 'commons',
    method: 'GET',
    uri: '/weed',
    query: { cropId: options.cropId },
    options,
  });

export const getAllWeedsKey = 'getAllWeeds';

export const useGetAllWeeds = <TData = types.PlagueWithCropResponse[]>(
  options: GetAllWeedsOptions,
  queryConfig?: Partial<UseQueryOptions<types.PlagueWithCropResponse[], unknown, TData>>,
) => useQuery({ queryFn: async () => getAllWeeds(options), queryKey: [getAllWeedsKey, options.cropId], ...queryConfig });
