import React from 'react';
import cn from 'clsx';

import { BaseButton, ButtonBaseProps, ExtendedComponent } from './BaseButton';
import { IconElement, renderIcon } from '../utils/reactUtils';

export interface ButtonProps {
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  text?: React.ReactNode;
  // modifiers
  raised?: boolean;
  unelevated?: boolean;
  outlined?: boolean;

  secondary?: boolean;
  inverted?: boolean;
  lower?: boolean;
  icon?: IconElement;
  trailingIcon?: IconElement;
}

export function Button<T extends ExtendedComponent = 'button'>({
  component,
  className,
  children,
  text,
  secondary,
  inverted,
  raised,
  unelevated,
  outlined,
  lower,
  icon,
  trailingIcon,
  ...props
}: ButtonBaseProps<T> & ButtonProps) {
  return (
    <BaseButton
      component={component}
      className={cn(
        'mdc-button',
        {
          'mdc-button--raised': raised,
          'mdc-button--unelevated': unelevated,
          'mdc-button--outlined': outlined,
          'mdc-button--secondary': secondary,
          'mdc-button--inverted': inverted,
          'mdc-button--lower': lower,
        },
        className,
      )}
      {...(props as any)}
    >
      <div className="mdc-button__ripple" />
      {icon && renderIcon(icon, 'mdc-button__icon')}
      <span className="mdc-button__label">{children || text}</span>
      {trailingIcon && renderIcon(trailingIcon, 'mdc-button__icon')}
    </BaseButton>
  );
}
Button.defaultProps = {
  component: 'button',
  ripple: true,
};
