/**
 * Auto-generated, do not edit, it will be overwritten
 * https://github.com/porfirioribeiro/openapi2ts
 */
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { request, ResponsePromise } from '..';
import * as types from './types';

export const getSoilTypeListByLocale = (): ResponsePromise<types.SoilTypeData[], any> =>
  request({
    module: 'commons',
    method: 'GET',
    uri: '/soilType',
  });

export const getSoilTypeListByLocaleKey = 'getSoilTypeListByLocale';

export const useGetSoilTypeListByLocale = <TData = types.SoilTypeData[]>(
  queryConfig?: Partial<UseQueryOptions<types.SoilTypeData[], unknown, TData>>,
) => useQuery({ queryFn: async () => getSoilTypeListByLocale(), queryKey: [getSoilTypeListByLocaleKey], ...queryConfig });
