/**
 * Auto-generated, do not edit, it will be overwritten
 * https://github.com/porfirioribeiro/openapi2ts
 */
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { request, ResponsePromise } from '..';
import * as types from './types';

export const getCountryList = (): ResponsePromise<types.CountryResponse[], any> =>
  request({
    module: 'accounts',
    method: 'GET',
    uri: '/commons/country',
    security: null,
  });

export const getCountryListKey = 'getCountryList';

export const useGetCountryList = <TData = types.CountryResponse[]>(
  queryConfig?: Partial<UseQueryOptions<types.CountryResponse[], unknown, TData>>,
) => useQuery({ queryFn: async () => getCountryList(), queryKey: [getCountryListKey], ...queryConfig });

export interface GetCountryByCodeOptions {
  countryCode: types.TCountryCode;
}

export const getCountryByCode = (options: GetCountryByCodeOptions): ResponsePromise<types.CountryResponse, GetCountryByCodeOptions> =>
  request({
    module: 'accounts',
    method: 'GET',
    uri: '/commons/country/{countryCode}',
    path: { countryCode: options.countryCode },
    security: null,
    options,
  });

export const getCountryByCodeKey = 'getCountryByCode';

export const useGetCountryByCode = <TData = types.CountryResponse>(
  options: GetCountryByCodeOptions,
  queryConfig?: Partial<UseQueryOptions<types.CountryResponse, unknown, TData>>,
) => useQuery({ queryFn: async () => getCountryByCode(options), queryKey: [getCountryByCodeKey, options.countryCode], ...queryConfig });
