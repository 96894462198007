import React from 'react';
import cn from 'clsx';
import './CircularProgress.scss';

export interface ICircularProgressProps {
  progress?: number;
  width?: number;
  height?: number;
  className?: string;
  style?: React.CSSProperties;
  center?: boolean;
}

const CircularProgress: React.FC<ICircularProgressProps> = ({ progress = -1, width = 24, height = width, className, style, center }) => {
  const size = Math.min(width, height);
  return (
    <svg
      role="progressbar"
      className={cn(
        'mdc-circular-progress',
        { 'mdc-circular-progress--indeterminate': progress === -1, 'mdc-circular-progress--center': center },
        className,
      )}
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      style={
        center
          ? {
              ...style,
              top: `calc(50% - ${width / 2}px)`,
              left: `calc(50% - ${height / 2}px)`,
            }
          : style
      }
    >
      <circle
        className="mdc-circular-progress__path"
        cx={size / 2}
        cy={size / 2}
        r={size / 2.4}
        fill="none"
        strokeWidth={Math.min(size * 0.1, 3.5)}
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default CircularProgress;
